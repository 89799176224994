const calibrationDuration = 20 * 1000; // 20sec
const scanDuration = 2 * 60 * 1000; // 2mins

let isInitializing = true;
let isScanning = true;
let canStop = false;
let start_time;

let stream;
let video;

let canvas;
let ctx;

let onFrameCallback = ({ type = '', timeElapsed = 0, isFingerInView = 0, fps = 0 }) => { };
let onScanFinishCallback = ({ raw_intensity = [], ppg_time = [], average_fps = 0 }) => { };

let raw_intensity = [];
let ppg_time = [];
let fps_array = [];

const setupCamera = async () => {
  video = document.getElementById("video");
  stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: { facingMode: "environment", aspectRatio: 16 / 9 },
  });
  const track = stream.getVideoTracks()[0];
  await track.applyConstraints({ advanced: [{ torch: true }] });
  video.srcObject = stream;
  return new Promise((resolve) => {
    video.onloadedmetadata = () => { resolve() };
  });
}

const stopScan = (errorOccurred = false, noCallback = false) => {
  stream.getTracks().forEach(function (track) { track.stop(); });
  isScanning = false;
  if (!noCallback) {
    if (errorOccurred) onScanFinishCallback({
      raw_intensity: [],
      ppg_time: [],
      average_fps: 0,
    })
    else onScanFinishCallback({
      raw_intensity,
      ppg_time,
      average_fps: Math.round(fps_array.reduce((sum, value) => sum + value, 0) / fps_array.length),
    });
  }
}

const calcConfidence = (rgb = { r: 0, g: 0, b: 0 }) => {
  const value = rgb.g + rgb.b;
  if (value <= 75) return 1;
  else if (value >= 125) return 0;
  else return ((125 - value) / 50);
}

const calcRGB_fromImageData = (imgData) => {
  let count = 0, sumRGB = { r: 0, g: 0, b: 0 };
  for (let i = 0; i < imgData.data.length; i += 4) {
    if (imgData.data[i + 3] > 0) {
      count++;
      sumRGB.r += imgData.data[i];
      sumRGB.g += imgData.data[i + 1];
      sumRGB.b += imgData.data[i + 2];
    }
  }
  return { r: (sumRGB.r / count), g: (sumRGB.g / count), b: (sumRGB.b / count) };
}

const drawCanvas = () => {
  ctx.save();
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  const bloodRegion = ctx.getImageData(0, 0, canvas.width, canvas.height);
  ctx.restore();
  return calcRGB_fromImageData(bloodRegion);
}

const scan = async (loop_start_time) => {
  const timeElapsed = performance.now() - start_time;
  try {
    if (isScanning) {
      if (timeElapsed <= calibrationDuration) {
        const avgRGB = drawCanvas();
        onFrameCallback({
          type: 'calibration',
          timeElapsed,
          isFingerInView: calcConfidence(avgRGB),
          fps: (1000 / (performance.now() - loop_start_time)),
        });
        requestAnimationFrame(scan);
      } else if (timeElapsed <= scanDuration) {
        if (timeElapsed >= (scanDuration / 2)) canStop = true;
        else canStop = false;
        const avgRGB = drawCanvas();
        raw_intensity.push(avgRGB);
        ppg_time.push((performance.now() - start_time));
        fps_array.push((1000 / (performance.now() - loop_start_time)));
        onFrameCallback({
          type: 'scan',
          timeElapsed,
          isFingerInView: calcConfidence(avgRGB),
          fps: fps_array[fps_array.length - 1],
        });
        requestAnimationFrame(scan);
      } else {
        stopScan();
      }
    }
  }
  catch (err) {
    console.log(err.message || 'facescan error');
    console.error(err);
    stopScan(true);
  }
}

const startScan = () => new Promise(async (resolve, reject) => {
  try {
    // Set up front-facing camera
    await setupCamera();
    video.play();

    // Create canvas and drawing context
    canvas = document.getElementById("canvasOutput");
    if (canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx = canvas.getContext("2d");
    }

    // start prediction loop
    isInitializing = false;
    isScanning = true;
    canStop = false;
    start_time = performance.now();
    requestAnimationFrame(scan);
    resolve()
  } catch (err) {
    stopScan(true);
    reject(err);
  }
})

export default {
  startScan,
  stopScan,
  onFrame: (callback = ({ type = '', timeElapsed = 0, isFingerInView = 0, fps = 0 }) => { }) => {
    if (typeof callback === 'function') onFrameCallback = callback;
  },
  onScanFinish: (callback = ({ raw_intensity = [], ppg_time = [], average_fps = 0 }) => { }) => {
    if (typeof callback === 'function') onScanFinishCallback = callback;
  },
  isInitializing: () => isInitializing,
  isScanning: () => isScanning,
  canStop: () => canStop
};