import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ProtectedRoute from "react-protected-route-component";
import Login from "./Screen/Login";
import OTP from "./Screen/OTP";
import FaceScan from "./Screen/FaceScan";
import FingerScan from "./Screen/FingerScan";
import SelectOption from "./Screen/SelectOption";
import Tutorial from "./Screen/Tutorial";
import ScanResult from "./Screen/ScanResult";
import BasicDetails from "./Screen/BasicDetails";
import Dashboard from "./Screen/Dashboard";
import Survey from "./Screen/Survey";
import BodyVitals from "./Screen/BodyVitals";
import HistoryDetails from "./Screen/HistoryDetails";
import UpdateProfile from "./Screen/UpdateProfile";
import HeartHealth from "./Screen/HeartHealth";
import PhysioFitness from "./Screen/PhysioFitness";
import StressIndex from "./Screen/StressIndex";
import { Box } from "@material-ui/core";
import logo from './Images/logo.png';
import safari from './Images/Safari_browser_logo.png';
import chrome from './Images/chrome-logo.png';
import firefox from './Images/firefox-logo.png';
import RightImage from './Images/web_img.png';
import Portrait from './Images/rotate-screen.png';
import Privacy from "./Screen/Privacy";
import Terms from "./Screen/Terms";
import TutorialList from "./Screen/TutorialList";
import Report from "./Screen/Report";
import Support from "./Screen/Support";

//test
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login} />
          {/* <Route exact path="/" component={SelectOption} /> */}
          {/* <Route exact path="/verify" component={OTP} />
          <Route exact path="/face-scan" component={FaceScan} />
          <Route exact path="/finger-scan" component={FingerScan} />
          <ProtectedRoute exact path="/select-option" component={SelectOption} />
          <Route exact path="/tutorial" component={Tutorial} />
          <Route exact path="/result" component={ScanResult} />
          <Route exact path="/basic-details" component={BasicDetails} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/survey" component={Survey} />
          <Route exact path="/account" component={UpdateProfile} />

          <Route exact path="/history-details" component={HistoryDetails} />
          <Route exact path="/vitals" component={BodyVitals} />
          <Route exact path="/heart" component={HeartHealth} /> */}
          <Route exact path="/report" component={Report} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/tutorial-list" component={TutorialList} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/verify" component={OTP} />
         
          <ProtectedRoute
            path="/face-scan"
            redirectRoute="/"
            guardFunction={() => {
              const token = localStorage.getItem("scan_token");
              if (token) {
                return true;
              } else {
                return false;
              }
            }}
            component={FaceScan}
            exact
          />
          <ProtectedRoute
            path="/finger-scan"
            redirectRoute="/"
            guardFunction={() => {
              const token = localStorage.getItem("scan_token");
              if (token) {
                return true;
              } else {
                return false;
              }
            }}
            component={FingerScan}
            exact
          />
          <ProtectedRoute
            path="/select-option"
            redirectRoute="/"
            guardFunction={() => {
              if (
                localStorage.getItem("user_details") &&
                localStorage.getItem("emp_id")
              ) {
                return true;
              } else {
                return false;
              }
            }}
            component={SelectOption}
            exact
          />
          <ProtectedRoute
            path="/result"
            redirectRoute="/"
            guardFunction={() => {
              if (
                localStorage.getItem("user_details") &&
                localStorage.getItem("scan_result")
              ) {
                return true;
              } else {
                return false;
              }
            }}
            component={ScanResult}
            exact
          />
          <ProtectedRoute
            path="/dashboard"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={Dashboard}
            exact
          />
          <ProtectedRoute
            path="/basic-details"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={BasicDetails}
            exact
          />
          <ProtectedRoute
            path="/survey"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={Survey}
            exact
          />
          <ProtectedRoute
            path="/account"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={UpdateProfile}
            exact
          />
          <ProtectedRoute
            path="/history-details"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={HistoryDetails}
            exact
          />
          <ProtectedRoute
            path="/vitals"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={BodyVitals}
            exact
          />
          <ProtectedRoute
            path="/heart"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={HeartHealth}
            exact
          />
          <ProtectedRoute
            path="/physiological"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={PhysioFitness}
            exact
          />
          <ProtectedRoute
            path="/stress"
            redirectRoute="/"
            guardFunction={() => {
              if (localStorage.getItem("user_details")) {
                return true;
              } else {
                return false;
              }
            }}
            component={StressIndex}
            exact
          />
          
        </Switch>
        <Box className="webbrowser">
          <Box className="Leftcol">
          <Box style={{textAlign:'left',display:'flex',justifyContent:'flex-start',width:'100%',marginBottom:120,}}><img src={logo} alt="logo" style={{ width: '25%', }} /></Box>
          <h3 style={{color:'#000',textAlign:'left',fontSize:35,width:'100%'}}>Please open this URL form your<br/>mobile browser</h3>
          <h4 style={{fontSize:24,color:'#727272',textAlign:'left',width:'100%',fontWeight:'normal'}}>Works best in</h4>
          <Box style={{textAlign:'left',width:'100%'}}><img src={safari} alt="safari logo" style={{ marginRight:15}} /><img src={chrome} alt="chrome logo" style={{ marginRight:15}} /><img src={firefox} alt="firefox logo" style={{ marginRight:15}} /></Box>
          </Box>
          <Box className="RightCol"><img src={RightImage} alt="Right image" /></Box>
        </Box>
        {/* <div className="PortraitMode">
          <img src={Portrait} alt="Portrait Image" style={{width:'25%'}} />
          <p style={{color:'#666',fontStyle:'italic',margin:0,}}>Please switch to portrait mode</p>
        </div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
