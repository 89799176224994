import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import nav from '../Images/nav.svg';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    list: {
      width: 250,
      '& .MuiListItem-gutters':{
        borderBottom:'1px rgba(0,0,0,0.05) solid'
      }
    },
    fullList: {
      width: 'auto',
    },
  });
function Nav(props) {
    const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    localStorage.removeItem("user_details");
    localStorage.removeItem("emp_id");
    window.location.href="/";
  }

  const myAccount = () => {

    if(localStorage.getItem("scan_result")){
        localStorage.removeItem("scan_result");
    }
    props.history.push("/account");

  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <Link to="/dashboard">
            <ListItem button key="Dashboard">
              <ListItemText primary="Dashboard"/>
            </ListItem>
          </Link>     
          <Link to="/account">
            <ListItem button key="My Account">
              <ListItemText primary="My Account"/>
            </ListItem>
          </Link>
          <Link to="/tutorial-list">
            <ListItem button key="Tutorials">
              <ListItemText primary="Tutorials"/>
            </ListItem>
          </Link>
          {/* <Link to="/report">
            <ListItem button key="Reports">
              <ListItemText primary="Reports"/>
            </ListItem>
          </Link> */}
          <Link to="/support">
            <ListItem button key="Support">
              <ListItemText primary="Support"/>
            </ListItem>
          </Link>
          <ListItem button key="Logout" onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
      </List>
      {/* <Divider /> */}
      
    </div>
  );
  return <div>
       {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><img src={nav} alt="nav" style={{width:'55%'}} /></Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
  </div>;
}

export default Nav;
