import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';

const calibrationDuration = 20 * 1000; // 20sec
const scanDuration = 2 * 60 * 1000; // 2mins

let isInitializing = true;
let isScanning = true;
let canStop = false;
let start_time;

let stream;
let video;
let canvas;
let ctx;

let onFrameCallback = ({ type = '', timeElapsed = 0, isLightMode = false, fps = 0 }) => { };
let onScanFinishCallback = ({ raw_intensity = [], ppg_time = [], average_fps = 0 }) => { };

let fmesh;
let faceCircleRegion = undefined;
let minX = Infinity, minY = Infinity, maxX = 0, maxY = 0;
let calibrationFPSArray = [];

let raw_intensity = [];
let ppg_time = [];
let fps_array = [];

const setupCamera = async () => {
  video = document.getElementById("video");
  stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: { facingMode: "user", aspectRatio: 16 / 9 },
  });
  video.srcObject = stream;
  return new Promise((resolve) => {
    video.onloadedmetadata = () => { resolve() };
  });
}

const stopScan = (errorOccurred = false, noCallback = false) => {
  stream.getTracks().forEach(function (track) { track.stop(); });
  isScanning = false;
  if (!noCallback) {
    if (errorOccurred) onScanFinishCallback({
      raw_intensity: [],
      ppg_time: [],
      average_fps: 0,
    })
    else onScanFinishCallback({
      raw_intensity,
      ppg_time,
      average_fps: Math.round(fps_array.reduce((sum, value) => sum + value, 0) / fps_array.length),
    });
  }
}

const drawPath = (points) => {
  const region = new Path2D();
  if (points?.length > 1) {
    const firstPoint = points.shift();
    region.moveTo(firstPoint[0], firstPoint[1]);
    points.forEach((point) => region.lineTo(point[0], point[1]));
    region.closePath();
  }
  return region;
}

const getRegion = async () => {
  const prediction = await fmesh.estimateFaces(video, { flipHorizontal: false });
  if (prediction?.[0]?.keypoints?.length > 0) {
    faceCircleRegion = drawPath(
      [10, 338, 297, 332, 284, 251, 389, 356, 454, 323, 361, 288,
        397, 365, 379, 378, 400, 377, 152, 148, 176, 149, 150, 136,
        172, 58, 132, 93, 234, 127, 162, 21, 54, 103, 67, 109].map(
          idx => [prediction[0].keypoints[idx].x, prediction[0].keypoints[idx].y]
        )
    );
    minX = Infinity; minY = Infinity; maxX = 0; maxY = 0;
    const points = [
      114, 121, 120, 119, 118, 117, 111, 116, 123, 147, 187, 207, 206, 203, 142, 126, 217, // Left  Cheek
      343, 350, 349, 348, 347, 346, 340, 345, 352, 376, 411, 427, 426, 423, 371, 355, 437, // Right Cheek
    ].map(idx => {
      const { x, y } = prediction[0].keypoints[idx];
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
      return [x, y];
    });
    const region = new Path2D();
    region.addPath(drawPath(points.slice(0, 17)));
    region.addPath(drawPath(points.slice(17)));
    return region;
  }
  return undefined;
}

const calcRGB_fromImageData = (imgData) => {
  let count = 0, sumRGB = { r: 0, g: 0, b: 0 };
  for (let i = 0; i < imgData.data.length; i += 4) {
    if (imgData.data[i + 3] > 0) {
      count++;
      sumRGB.r += imgData.data[i];
      sumRGB.g += imgData.data[i + 1];
      sumRGB.b += imgData.data[i + 2];
    }
  }
  return { r: (sumRGB.r / count), g: (sumRGB.g / count), b: (sumRGB.b / count) };
}


const drawCanvas = (drawRegion = undefined, cutRegion = { region: undefined, rect: { x: 0, y: 0, w: 0, h: 0 } }) => {
  ctx.save();
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  if (cutRegion.region) ctx.clip(cutRegion.region);
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  const bloodRegion = ctx.getImageData(cutRegion.rect.x, cutRegion.rect.y, cutRegion.rect.w, cutRegion.rect.h);
  if (cutRegion.region) {
    ctx.restore();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  }
  if (drawRegion) {
    ctx.lineWidth = 3;
    ctx.strokeStyle = 'rgba(107,184,248,0.8)';
    ctx.stroke(drawRegion);
  }
  ctx.restore();
  return calcRGB_fromImageData(bloodRegion);
}

const scan = async (loop_start_time) => {
  const timeElapsed = performance.now() - start_time;
  try {
    if (isScanning) {
      if (timeElapsed <= calibrationDuration) {
        drawCanvas(undefined, { region: undefined, rect: { x: 0, y: 0, w: canvas.width, h: canvas.height } });
        getRegion().then(() => calibrationFPSArray.push((1000 / (performance.now() - loop_start_time))));
        onFrameCallback({
          type: 'calibration',
          timeElapsed,
          isLightMode: false,
          fps: calibrationFPSArray[calibrationFPSArray.length - 1],
        });
        requestAnimationFrame(scan);
      } else if (timeElapsed <= scanDuration) {
        if (timeElapsed >= (scanDuration / 2)) canStop = true;
        else canStop = false;
        if ((calibrationFPSArray.reduce((sum, value) => sum + value, 0) / calibrationFPSArray.length) < 15) {
          const avgRGB = drawCanvas(faceCircleRegion, { region: undefined, rect: { x: minX, y: minY, w: maxX - minX, h: maxY - minY } });
          raw_intensity.push(avgRGB);
          ppg_time.push((performance.now() - start_time));
          fps_array.push((1000 / (performance.now() - loop_start_time)));
          onFrameCallback({
            type: 'scan',
            timeElapsed,
            isLightMode: true,
            fps: fps_array[fps_array.length - 1],
          });
        } else {
          const region = await getRegion();
          const avgRGB = drawCanvas(faceCircleRegion, { region, rect: { x: minX, y: minY, w: maxX - minX, h: maxY - minY } });
          raw_intensity.push(avgRGB);
          ppg_time.push((performance.now() - start_time));
          fps_array.push((1000 / (performance.now() - loop_start_time)));
          onFrameCallback({
            type: 'scan',
            timeElapsed,
            isLightMode: false,
            fps: fps_array[fps_array.length - 1],
          });
        }
        requestAnimationFrame(scan);
      } else {
        stopScan();
      }
    }
  }
  catch (err) {
    console.log(err.message || 'facescan error');
    console.error(err);
    stopScan(true);
  }
}

const startScan = () => new Promise(async (resolve, reject) => {
  try {
    fmesh = await faceLandmarksDetection.createDetector(faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh, {
      runtime: 'mediapipe',
      solutionPath: '/model',
      refineLandmarks: false,
      maxFaces: 1
    });


    // Set up front-facing camera
    await setupCamera();
    video.play();

    // Create canvas and drawing context
    canvas = document.getElementById("canvasOutput");
    if (canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx = canvas.getContext("2d");
    }

    // start prediction loop
    isInitializing = false;
    isScanning = true;
    canStop = false;
    start_time = performance.now();
    requestAnimationFrame(scan);
    resolve()
  } catch (err) {
    stopScan(true);
    reject(err);
  }
})

export default {
  startScan,
  stopScan,
  onFrame: (callback = ({ type = '', timeElapsed = 0, isLightMode = false, fps = 0 }) => { }) => {
    if (typeof callback === 'function') onFrameCallback = callback;
  },
  onScanFinish: (callback = ({ raw_intensity = [], ppg_time = [], average_fps = 0 }) => { }) => {
    if (typeof callback === 'function') onScanFinishCallback = callback;
  },
  isInitializing: () => isInitializing,
  isScanning: () => isScanning,
  canStop: () => canStop
};