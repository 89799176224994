import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import { Box } from "@material-ui/core";
import Nav from "../Component/Nav";
import Select from "react-dropdown-select";
import { Bar } from "react-chartjs-2";
import { dateFilter } from "../Util/KeyInsights";
import { apiAxios, apiAxios2 } from "../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router";
import { BiArrowBack } from "react-icons/bi";
import moment from "moment";
import { Pagination } from "@material-ui/lab";


const genData = () => ({
  labels: ["<18", "18-29", "29-39", "40-49", "59-59", "60-69", "70-79", "80+"],
  datasets: [
    {
      type: "bar",
      label: "Age Group",
      data: [80, 90, 75, 50, 40, 30, 10, 6],
      backgroundColor: [
        "#0085c7",
        "#2cbbe7",
        "#006cb1",
        "#0085c7",
        "#0085c7",
        "#0085c7",
        "#0085c7",
        "#0085c7",
      ],
      borderColor: "white",
      options: {
        scales: {
          xAxes: [{ barPercentage: 0.1 }],
        },
      },
    },
  ],
});

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

function HistoryDetails(props) {
  const classes = useStyles();
  const [vitalsData, setVitalsData] = useState(genData());

  const [paramName, setParamName] = useState("");

  const [filterOption, setFilterOption] = React.useState("weekly");
  const [vitalsName, setVitalsName] = React.useState("");
  const [vitalsValue, setVitalsValue] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);
  const [type, setType] = React.useState("");

  // useEffect(() => {
  //   const interval = setInterval(() =>  setVitalsData(genData()), 5000);

  //   return () => clearInterval(interval);
  // }, []);

  const getData = () => {
    console.log(props.location.state);
    console.log(props);

    let { vitals, name, type } = props.location.state;

    setParamName(name);

    if (vitals == "bp") {
      vitals = "BP";
    } else if (vitals == "heart_rate") {
      vitals = "Heart Rate";
    } else if (vitals == "resp_rate") {
      vitals = "Respiratory Rate";
    } else if (vitals == "oxy_sat_prcnt") {
      vitals = "Oxygen Saturation";
    }

    setType(type);
    setVitalsName(vitals);

    let end_dt = moment(new Date()).format("YYYY-MM-DD");
    let start_dt;
    switch (filterOption) {
      case "weekly":
        start_dt = moment(end_dt).subtract(7, "days").format("YYYY-MM-DD");
        break;
      case "yearly":
        start_dt = moment(end_dt).subtract(1, "years").format("YYYY-MM-DD");
        break;
      case "monthly":
        start_dt = moment(end_dt).subtract(30, "days").format("YYYY-MM-DD");
        break;
      default:
        start_dt = moment(end_dt).subtract(60, "days").format("YYYY-MM-DD");
    }

    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
      vital_action: vitals,
      employee_id: localStorage.getItem("emp_id"),
      table_config_rows_per_page: 10,
      table_config_page_no: currentPage,
      //start_date: start_dt,
      //end_date: end_dt,
    };

    apiAxios2
      .post("/users/sdk/list_vitals_data_private", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails.id_token,
        },
      })
      .then((response) => {
        console.log(response.data.response);

        if (response.data.statusCode == 200) {
          setVitalsData(response.data);
          console.log(response.data.total_pages_count);
          setTotalPages(response.data.total_pages_count);
          setCurrentPage(response.data.current_page);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //setShowLoader(false);
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //setShowLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, [currentPage, filterOption]);

  return (
    <div>
      <Box className={classes.History}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ width: "40%" }} />
          <Box style={{ marginRight: -15 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "left",
              marginBottom: 0,
            }}
          >
            {(type == "vitals") ? (
              <Box style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center', marginTop: 10, }}>
                <Link to="/vitals">
                  <BiArrowBack size="26" style={{ color: 'rgb(78 90 98)', marginTop: 5, }} />
                </Link>
                <h3 style={{ margin: '0px 0px 0px 20px', fontSize: '18px', fontFamily: 'Poppins', fontWeight: '600', color: 'rgb(51 73 139)' }}>
                  {vitalsName == "BP" ? "Blood Pressure" : vitalsName}
                </h3>
              </Box>
            ) : (
              <Box style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                <Link to="/heart">
                  <BiArrowBack size="26" style={{ color: 'rgb(78 90 98)', marginTop: 5, }} />
                </Link>
                <h3 style={{ margin: '0px 0px 0px 20px', fontSize: '18px', fontFamily: 'Poppins', fontWeight: '600', color: 'rgb(51 73 139)' }}>

                  {paramName == "sdnn"
                    ? "HRV (SDNN)"
                    : paramName == "co"
                      ? "Cardiac Output"
                      : paramName == "map"
                        ? "Mean Arterial Pressure"
                        : "Vo2Max"}
                </h3>
              </Box>
            )}
            {/* <Select
              options={dateFilter()}
              onChange={(values) =>
                setFilterOption(values[0] ? values[0].value : values.value)
              }
              values={dateFilter().filter(
                (option) => option.value == filterOption
              )}
              searchable={false}
            /> */}
          </Box>

          <Box className={classes.RecordTable}>
            {vitalsData.response ? (
              vitalsData.response.map((vitals, index) => (
                <Box key={index} className={classes.Trow}>
                  <Box className={classes.Tdcol} style={{ textAlign: 'left' }}>
                    <p className={classes.PostureText}>{vitals.posture}</p>
                    {vitalsName == "BP" ? (
                      <>
                        {paramName == "co" ?
                          <p className={classes.ValueText} style={{ textAlign: "left" }}>
                            <span>
                              {vitals.cardiac_out.toFixed(2)}
                            </span>{" "}

                            Ltrs
                          </p>
                          : paramName == "map" ?
                            <p className={classes.ValueText} style={{ textAlign: "left" }}>
                              <span className={classes.ValueText}>
                                {vitals.map.toFixed(2)}
                              </span>{" "}

                              mmHg
                            </p>
                            :
                            <p className={classes.ValueText} style={{ textAlign: "left" }}>
                              <span className={classes.ValueText}>
                                {Math.round(vitals.bp_sys)}/
                                {Math.round(vitals.bp_dia)}
                              </span>{" "}

                              mmHg
                            </p>
                        }
                      </>
                    ) : vitalsName == "Heart Rate" ? (
                      <p className={classes.ValueText}>
                        {paramName == "sdnn" ? (
                          <>
                            <span>
                              {vitals.sdnn == "--"
                                ? "--"
                                : Math.round(vitals.sdnn)}
                            </span>{" "}
                            ms
                          </>
                        ) : paramName == "vo2max" ? (
                          <>
                            <span style={{ textAlign: "left" }}>
                              {vitals.vo2max}
                            </span>{" "}
                            ml/kg/min
                          </>
                        ) : (
                          <>
                            <span>{vitals.heart_rate}</span> bpm
                          </>
                        )}
                      </p>
                    ) : vitalsName == "Respiratory Rate" ? (
                      <p className={classes.ValueText} style={{ textAlign: "left" }}>
                        <span>{vitals.resp_rate}</span> /min
                      </p>
                    ) : (
                      <p className={classes.ValueText}>
                        <span>{vitals.oxy_sat_prcnt}</span> %
                      </p>
                    )}
                    <p className={classes.ValueRange} style={{ fontFamily: 'Poppins', fontSize: 12, color: 'rgb(163 171 175)' }}>0-23</p>
                  </Box>

                  <Box className={classes.Tdcol} style={{ textAlign: 'right' }}>
                    <p style={{ color: '#61b80d' }}>Normal</p>
                    <p className={classes.Date}>{moment(vitals.created_at).format("DD-MM-YYYY,  hh:mm A")}</p>
                  </Box>

                </Box>
              ))
            ) : (
              <></>
            )}
          </Box>
          <Box className={classes.Pagination} style={{ width: '100%', textAlign: 'center', }}>
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              style={{ float: "right", marginTop: "10px" }}
              size="small"
            />
          </Box>
        </Box>

        {/* <Box className={classes.Trow}>
              <Box className={classes.Tdcol}>24/01/2022, 04:56pm</Box>
              <Box className={classes.Tdcol}>Resting</Box>
              <Box className={classes.Tdcol}><p><span>78</span> bpm</p></Box>
          </Box>
          <Box className={classes.Trow}>
          <Box className={classes.Tdcol}>24/01/2022, 04:56pm</Box>
              <Box className={classes.Tdcol}>Resting</Box>
              <Box className={classes.Tdcol}><p><span>78</span> bpm</p></Box>
          </Box>
          <Box className={classes.Trow}>
          <Box className={classes.Tdcol}>24/01/2022, 04:56pm</Box>
              <Box className={classes.Tdcol}>Resting</Box>
              <Box className={classes.Tdcol}><p><span>78</span> bpm</p></Box>
          </Box> */}

      </Box >

    </div >
  );
}

export default HistoryDetails;
const useStyles = makeStyles(() => ({
  History: {
    display: "flex",
    left: 0,
    right: 0,
    flexDirection: "column",
    padding: "0 30px 100px",
    height: '100%',
    paddingTop: 20,
    "& a": {
      textDecoration: "none",
    },
    "& .MuiListItem-button:hover": {
      background: "rgba(47, 153, 241, 0.1)",
    },
    "& .css-wmw4vi-ReactDropdownSelect": {
      borderRadius: 40,
      padding: "8px 10px",
    },
    "& h3": {
      fontFamily: "Poppins",
      fontSize: 20,
      color: "#2F99F1",
      fontWeight: "400",
      margin: "0 30px 0 0",
    },
  },
  Pagination: {
    '& .Mui-selected': {
      background: '#2f99f1 !important'
    }
  },
  FixedBtn: {
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1e3063',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    fontSize: 16,
    zIndex: 99999,
    borderRadius: 40,
    border: '1px #1e3063 solid',

  },
  BottomLink: {
    width: '60%',
    background: '#fff',
    padding: '0 20%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  ValueText: {
    fontFamily: 'Poppins',
    color: '#192853',
  },
  Date: {
    fontFamily: 'Poppins',
    color: '#68718d'
  },
  PostureText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#2f99f1'
  },
  ValueRange: {
    color: 'rgb(183 186 189)'
  },
  HistoryList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(47, 153, 241, 0.06)",
    borderRadius: 10,
    padding: 20,
    marginBottom: 15,
    "& h3": {
      fontSize: 20,
      color: "#192853",
      margin: 0,
      fontFamily: "Poppins",
      fontWeight: "400",
    },
    "& h4": {
      fontSize: 24,
      color: "#2F99F1",
      margin: 0,
      fontFamily: "Poppins",
      lineHeight: "22px",
      fontWeight: "400",
      textAlign: "center",
    },
    "& p": {
      color: "#5C5C5C",
      fontSize: 15,
      margin: 0,
      fontFamily: "Poppins",
      textAlign: "center",
    },
  },
  RecordTable: {
    width: "100%",
    marginTop: 20,
  },
  Trow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '10px 15px',
    borderRadius: 10,
    marginBottom: 10,
    "&:nth-child(even)": {
      background: "#f9fafb",
      boxShadow: '1px 1px 5px 0 rgb(0 0 0 / 8%)',
    },
    "&:nth-child(odd)": {
      background: "#f9fafb",
      boxShadow: '1px 1px 5px 0 rgb(0 0 0 / 8%)',
    },
  },
  Tdcol: {
    fontFamily: "Poppins",
    fontSize: 12,
    color: '#68718d',
    "& p": {
      margin: 0,
      fontFamily: 'Poppins',
      "& span": {
        fontSize: 15,
        fontWeight: "600",
      },
    },
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
  },
}));
