import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import Low from '../Images/Low_stress.png';
import Normal from '../Images/Normal_Stress.png';
import High from '../Images/High_stress.png';
import VeryHeigh from '../Images/Very_high_stress.png';
import Elevated from '../Images/Elevated_stress.png';
import risk from '../Images/risk.png';
import heart from '../Images/heart.png';
import blood from '../Images/blood.png';
import hrv from '../Images/hrv.png';
import cardiac from '../Images/cardiac.png';
import hearthealth from '../Images/heart_health2.png';
import rpm from '../Images/rpm_ad.png';
import bodyvitals from '../Images/body_vitals.png';
import physio from '../Images/physio.png';
import stress from '../Images/stress.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import Nav from '../Component/Nav';
import GaugeChart from 'react-gauge-chart'
import moment from "moment";
import { BiArrowBack } from "react-icons/bi";
import ChartData from '../Component/ChartData';
import { insightsList } from "../Util/KeyInsights";

import { apiAxios, apiAxios2 } from "../Util/ApiAxios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from 'react-motion-animate';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function StressIndex(props) {
  const classes = useStyles();
  const [greetings, setGreetings] = React.useState("Welcome,");
  const [userDetails, setUserDetails] = React.useState("");

  const [insights, setInsights] = React.useState([]);

  const [latestData, setLatestData] = React.useState("");

  const user = JSON.parse(localStorage.getItem("user_details"));

  const startScan = (flag) => {

    if (flag == 1) {
      props.history.push("/face-scan");
    }
    else {
      props.history.push("/finger-scan");
    }

  };

  useEffect(() => {

    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      setGreetings("Good Morning");
    } else if (curHr < 18) {
      setGreetings("Good Afternoon");
    } else {
      setGreetings("Good Evening");
    }

    let ins_arr = insightsList();
    setInsights(ins_arr);
    //setUserDetails( JSON.parse(localStorage.getItem("user_details")) );

    //getProfile();
    getView();


  }, []);



  const getView = () => {

    let dt = new Date();

    let data = {
      action: "list_scan_UL",
      employee_id: localStorage.getItem("emp_id"),
      table_config_rows_per_page: 1,
      table_config_page_no: 1,
    };


    apiAxios2
      .post("/users/sdk/test_api_private", data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": user.id_token
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 200) {

          setLatestData(response.data.result[0]);

        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //setShowLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //setShowLoader(false);
      });


  }


  return <>
    <Box className={classes.Scan}>
      <Box className={classes.logo} style={{ minHeight: '10%' }}>
        <Box style={{ width: '80%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
          <Link to="/dashboard"><BiArrowBack size="26" style={{ color: 'rgb(78 90 98)',marginTop:5,}} /></Link><h6 className={classes.name}><span>Stress Index</span></h6>
        </Box>
        <Box>
          <Nav />
        </Box>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Box style={{padding:'4%', textAlign: 'right'}}>
        <span style={{fontFamily:'Poppins',fontSize:14, color:'#577397'}}>Weekly</span>           
        </Box> */}

        {latestData ?
          <>

            <Box className={classes.vitalsBox} style={{ display: 'flex', flexDirection: 'column', position: 'relative', marginTop: 40, }}>
              <h2 style={{ fontFamily: 'Poppins', fontSize: 16, color: 'rgb(51 73 139)', margin: '0 0 10px', fontWeight: '400', }}>Stress Level</h2>


              <h3 style={{ margin: '0', fontFamily: 'Poppins', color: '#2f99f1', fontWeight: '500', fontSize: 14, position: 'absolute', bottom: 14, right:'23%', }}>
                {(latestData.heart_scores.stress_index == "--") ? "--" : latestData.heart_scores.stress_index.toFixed(2)}
              </h3>

              {latestData.heart_scores.stress_index !== "--" &&
                <Box>
                  {(latestData.heart_scores.stress_index.toFixed(2) < 0.50) ?
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                      <img src={Low} alt="Low" style={{ width: '20%', marginBottom: 10, }} />
                      <h4 style={{ color: '#32b509', margin: '0', fontFamily: 'Poppins' }}>LOW</h4>
                      <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(74 116 149)', margin: 0, }}>Stress Index (SI) : </p>
                    </Box>
                    :
                    (latestData.heart_scores.stress_index.toFixed(2) >= 0.50 && latestData.heart_scores.stress_index.toFixed(2) < 1.50) ?
                      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={Normal} alt="Normal" style={{ width: '20%', marginBottom: 10, }} />
                        <h4 style={{ color: '#61b80d', margin: '0', fontFamily: 'Poppins', fontSize: 12, }}>NORMAL</h4>
                        <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(74 116 149)', margin: 0, }}>Stress Index (SI) : </p>
                      </Box>
                      :
                      (latestData.heart_scores.stress_index.toFixed(2) >= 1.50 && latestData.heart_scores.stress_index.toFixed(2) < 5) ?
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                          <img src={Elevated} alt="Elevated" style={{ width: '20%', marginBottom: 10, }} />
                          <h4 style={{ color: '#eea508', margin: '0', fontFamily: 'Poppins' }}>ELEVATED</h4>
                          <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(74 116 149)', margin: 0, }}>Stress Index (SI) : </p>
                        </Box>
                        :
                        (latestData.heart_scores.stress_index.toFixed(2) >= 5 && latestData.heart_scores.stress_index.toFixed(2) < 9) ?
                          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <img src={High} alt="High" style={{ width: '20%', marginBottom: 10, }} />
                            <h4 style={{ color: '#f31e2e', margin: '0', fontFamily: 'Poppins' }}>HIGH</h4>
                            <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(74 116 149)', margin: 0, }}>Stress Index (SI) : </p>
                          </Box>
                          :
                          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <img src={VeryHeigh} alt="Very High" style={{ width: '20%', marginBottom: 10, }} />
                            <h4 style={{ color: '#b4020f', margin: '0', fontFamily: 'Poppins' }}>VERY HIGH</h4>
                            <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(74 116 149)', margin: 0, }}>Stress Index (SI) : </p>
                          </Box>
                  }
                </Box>
              }

            </Box>

            <Box className={classes.vitalsBox}>
              <Box>
                {latestData.heart_scores.rmssd == "--" ? (
                  <>
                    <p className={classes.content}>
                      Not enought data to process. This happens when
                      the signal strength of the scan is poor due to
                      improper light or too much movements.
                    </p>
                  </>
                ) : (
                  <>
                    {latestData.heart_scores.rmssd < 65 ? (
                      <>
                        {latestData.heart_rate > 100 ||
                          latestData.vitals.bp_sys > 130 ||
                          latestData.vitals.bp_dia > 90 ? (
                          <>
                            <h3 style={{ fontFamily: 'Poppins', color: 'rgb(51 73 139)', fontSize: 17, marginTop: 0, }}>More Sympathetic Interactions</h3>
                            <p
                              style={{
                                color: "#787878",
                                fontSize: "12px",
                                textAlign: "center",
                                padding: "0 20px",
                                margin: "5px 0 0 0",
                                fontWeight: '400',
                                fontFamily: 'Poppins'
                              }}
                            >
                              Although HRV manifests as a function of
                              your heart rate, it actually originates
                              from your nervous system. Your autonomic
                              nervous system, which controls the
                              involuntary aspects of your physiology,
                              has two branches, parasympathetic
                              (deactivating) and sympathetic
                              (activating).
                            </p>
                            <p
                              style={{
                                color: "#787878",
                                fontSize: "12px",
                                textAlign: "center",
                                padding: "0 20px",
                                margin: "5px 0 0 0",
                                fontWeight: '400',
                                fontFamily: 'Poppins'
                              }}
                            >
                              {" "}
                              The <b>
                                sympathetic nervous system
                              </b>{" "}
                              (often called “fight or flight”)
                              reflects responses to things like stress
                              and exercise, and increases your heart
                              rate and blood pressure.{" "}
                            </p>
                          </>
                        ) : (
                          <>
                            <h3 style={{ fontFamily: 'Poppins', color: 'rgb(51 73 139)', fontSize: 17, marginTop: 0, }}>
                              More Parasympathetic Interactions
                            </h3>
                            <p
                              style={{
                                color: "#787878",
                                fontSize: "12px",
                                textAlign: "center",
                                padding: "0 20px",
                                margin: "5px 0 0 0",
                                fontWeight: '400',
                                fontFamily: 'Poppins'
                              }}
                            >
                              Although HRV manifests as a function of
                              your heart rate, it actually originates
                              from your nervous system. Your autonomic
                              nervous system, which controls the
                              involuntary aspects of your physiology,
                              has two branches, parasympathetic
                              (deactivating) and sympathetic
                              (activating).
                            </p>
                            <p
                              style={{
                                color: "#787878",
                                fontSize: "12px",
                                textAlign: "center",
                                padding: "0 20px",
                                margin: "5px 0 0 0",
                                fontWeight: '400',
                                fontFamily: 'Poppins'
                              }}
                            >
                              The{" "}
                              <b>parasympathetic nervous system</b>{" "}
                              (often referred to as “rest and digest”)
                              handles inputs from internal organs,
                              like digestion or your fingernails and
                              hair growing.{" "}
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 style={{ fontFamily: 'Poppins', color: 'rgb(51 73 139)', fontSize: 17, marginTop: 0, }}>Balanced <br />*ANS Interactions</h3>
                        <p
                          style={{
                            color: "#787878",
                            fontSize: "12px",
                            textAlign: "center",
                            padding: "0 20px",
                            margin: "5px 0 0 0",
                            fontWeight: '400',
                            fontFamily: 'Poppins'
                          }}
                        >
                          When you have high heart rate variability,
                          it means that your body is responsive to
                          both sets of inputs (parasympathetic and
                          sympathetic). This is a sign that your
                          nervous system is balanced, and that your
                          body is very capable of adapting to its
                          environment and performing at its best.
                        </p>
                      </>
                    )}
                  </>
                )}
              </Box>

              <p style={{ fontFamily: 'Poppins', fontSize: 12, color: 'rgb(144 157 167)', marginBottom: 0, }}>*Autonomic Nervous Systems (ANS)</p>
            </Box>

          </>
          :
          <></>
        }

        <h6
          style={{
            fontFamily: "Poppins",
            fontSize: 16,
            color: "#2F99F1",
            marginTop: 0,
            marginBottom: 0,
            fontWeight: "600",
            textAlign: "left",
            padding: "0 1%",
          }}
        >
          Key Insights
        </h6>
        {(insights.length > 0) ?
          <>
            <video
              src={insights[4].video}
              id="video"
              controls="true"
              style={{
                width: "100%",
                height: "100%",
                margin: "5% 0",
                borderRadius: 15,
              }}
            ></video>
            <p className={classes.content}>{insights[4].content}</p>

            <p
              className={classes.content}
              style={{ textAlign: "right" }}
            >
              Source: {insights[4].source}
            </p>
          </>
          :
          <></>
        }





      </Box>
    </Box>
  </>;
}

export default StressIndex;
const useStyles = makeStyles(() => ({
  sliderClass: {
    "& .control-dots": {
      display: "none",
    },
    "& .carousel .slider": {
      height: "auto",
    },
    "& .carousel.carousel-slider .control-arrow": {
      bottom: "initial",
      marginTop: '30%'
    },
    '& .carousel .thumbs-wrapper': {
      margin: '0'
    },
    "& .carousel .control-next.control-arrow:before": {
      borderLeft: "8px solid #333",
    },
    "& .carousel .control-prev.control-arrow:before": {
      borderRight: "8px solid #333",
    },
    "& .carousel-status": {
      display: "none",
    },
    "& .carousel .control-next.control-arrow": {
      right: 5,
    },
    "& .carousel.carousel-slider .control-arrow:hover": {
      background: "#fff",
    },
    "& .carousel .control-prev.control-arrow": {
      left: 5,
    }

  },
  vitalsBox: {
    padding: '5% 0',
    margin: '0 0 25px 0',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 10px 0 rgba(0,0,0,0.1)'
  },
  Scan: {
    display: 'flex',
    padding: '30px 7.5%',
    left: 0,
    right: '0',
    background: '#fff',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none'
    },

  },
  content: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0 1%",
    color: "#577397",
    fontFamily: 'Poppins'
  },
  healthscorebox: {
    width: '100%',
    background: 'linear-gradient(176deg, #eaf0f5 0%, #e9ecef 92.7%)',
    borderRadius: '10px',
    height: '25%',
  },
  scanbox: {
    height: '25%',
    width: '100%',
  },
  healthscoreboxleft: {
    width: '65%',
    float: 'left',
    height: '100%',
    paddingTop: '5%'
  },
  histIcon: {
    width: '25%',
    textAlign: 'center',
    float: 'left'
  },
  histText: {
    fontFamily: 'Poppins',
    fontSize: '0.8em',
    fontWeight: '300',
    margin: '0'
  },
  scanboxleft: {
    width: '45%',
    float: 'left',
    textAlign: 'left',
    '& p': {
      fontSize: '12px',
      color: '#000',
      fontFamily: 'Poppins',
    }
  },
  healthscoreboxright: {
    width: '35%',
    color: '#1a2e5c',
    paddingTop: '12%',
    float: 'left',
    textAlign: 'left',
    height: '100%',
  },
  scanboxright: {
    width: '55%',
    float: 'left',
    textAlign: 'center',
  },
  riskbar: {
    padding: '10%',
    height: '2.5em',
    marginTop: '15%',
    borderRadius: '100%',
    border: '3px solid #fff'
  },
  subScan: {
    fontSize: '0.7em',
    color: '#5c6374',
    margin: '0',
    padding: '0 10%',
    fontFamily: 'Poppins',
  },
  optionIcon: {
    width: '100%',
    textAlign: 'center',
    height: '50%',
    '& h4': {
      fontFamily: 'Poppins',
      color: '#192853',
      fontWeight: '400',
      fontSize: 16,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  ScanInfo: {
    width: '100%',
    background: 'linear-gradient(180deg, #2F99F1 -92.7%, #192853 92.7%)',
    height: '38vh',
    '& p': {
      fontFamily: 'Poppins',
      color: 'rgba(255,255,255,0.7)',
      fontWeight: '400',
      fontSize: 12,
      padding: '0 20px',
      marginTop: 0,
      marginBottom: 30,
      lineHeight: 1.6,
    },
    '& h4': {
      fontFamily: 'Poppins',
      color: 'rgba(255,255,255,0.8)',
      fontWeight: '400',
      fontSize: 16,
      marginTop: 20,
      marginBottom: 10,
    },
    '& h3': {
      fontSize: 16,
      fontFamily: 'Poppins',
      color: '#fff',
      fontWeight: '400',
      marginTop: 40,
      marginBottom: 0,
      '& span': {
        fontSize: 24,
        fontWeight: '600',
        color: '#2F99F1',
        marginRight: 5,
      }
    }
  },
  ScanCamera: {
    width: '100%',
    height: '49.7vh'
  },
  logo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '15%',
  },
  CancelBtn: {
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#2F99F1',
    textTransform: 'capitalize',
    borderRadius: 50,
    padding: '5px 20px'
  },

  loginbtn: {
    background: 'linear-gradient(90deg, #2F99F1 0%, #192853 100%)',
    padding: '0 20px',
    width: '162px',
    height: '50px',
    borderRadius: '50px',
    color: '#fff',
    marginTop: '0',
    textTransform: 'capitalize',
    boxShadow: '0 0 0 0 #2680db24',
    animation: 'pulse 1.5s infinite',

  },
  name: {
    margin: '0 0 0 10px',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    color: 'rgb(51 73 139)'
  },
  greeting: {
    fontWeight: '300',
    color: '#3399f1',
    marginBottom: 0
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(.9)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 50px #2680db24',
    },
    '100%': {
      transform: 'scale(.9)',
      boxShadow: '0 0 0 0 #2680db24',
    }
  }

}));