import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Nav from "../Component/Nav";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { apiAxios2, API_KEY, SECRET_KEY } from "../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Survey(props) {
  const classes = useStyles();

  const [isloading, setIsLoading] = useState(true);
  const [surveyData, setSurveyData] = useState([]);
  const [responseArray, setResponse] = useState([]);
  const [dropOptions, setDropOption] = useState({});
  const [change, setChange] = useState(false);
  const [ansobject, setansObject] = useState(null);
  const [dropControl, setdropControl] = React.useState([]);
  const [answerError, setAnswerError] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
      table_config_rows_per_page: 5,
      table_config_page_no: 1,
    };
    apiAxios2
      .post("/users/questions/list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails.id_token,
        },
      })
      .then((response) => {
        let data = response.data.response.response_packages;
        let answerArray = [];
        setSurveyData(data);
        data.map((item, index) => {
          answerArray.push({ id: item.id, answer: "" });
        });
        setResponse(answerArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeSelected = (e, id, option = false) => {
    setAnswerError(false);
    let value = !option ? e.target.value : "yes_option";
    let answers = responseArray;
    let data = dropOptions;
    answers.forEach((item) => {
      if (item.id == id) {
        item.answer = value;
      }
    });
    data[id] = value === "yes_option";
    setDropOption(data);
    setResponse(answers);
    setChange(!change);
  };

  const handleDropDownChangeSelected = (e, id) => {
    setAnswerError(false);
    let value = e.target.value;
    let answers = responseArray;
    answers.forEach((item) => {
      if (item.id == id) {
        item.answer = value;
      }
    });
    setResponse(answers);
    setChange(!change);
  };

  const handleTextInput = (e, id) => {
    let value = e.target.value;
    setAnswerError(false);
    let answers = responseArray;
    answers.forEach((item) => {
      if (item.id == id) {
        item.answer = value;
      }
    });
    setResponse(answers);
    setChange(!change);
  };

  useEffect(() => {
    let data = surveyData;
    data.forEach((item) => {
      let value;
      if (responseArray.find((i) => i.id == item.id)) {
        item.question_answers = value ? value : null;
      }
    });
    setSurveyData(data);
  }, [change]);

  function onUserSubmit() {
    let isValid = true;

    let data = [];

    data = responseArray;

    data.find((item) => {
      if (
        item.answer != "" &&
        item.answer != " " &&
        item.answer != null &&
        item.answer != "yes_option"
      ) {
        setAnswerError(false);
      } else {
        isValid = false;
      }
    });
    if (isValid) {
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      let sendData = {
        answers: responseArray,
      };

      apiAxios2
        .post("/users/questions/survey-answer", sendData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: userDetails.id_token,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            props.history.push("/dashboard");
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      setAnswerError(true);
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box className={classes.Survey}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ width: "40%" }} />
          <Box style={{ marginRight: -15 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100%",
            height: "68vh",
            marginTop: "3vh",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "500",
              textAlign: "left",
              color: "#2F99F1",
              paddingLeft: 10,
            }}
          >
            Survey Questions
          </h3>
          {answerError ? (
            <p
              style={{
                color: "#dc3545",
                fontSize: ".875em",
                marginTop: "0.25rem",
                textAlign: "right",
              }}
            >
              All answers are mandatory
            </p>
          ) : (
            <></>
          )}

          <List component="nav">
            {surveyData
              ? surveyData.map((survey) => (
                  <ListItem
                    button
                    className={classes.surveyList}
                    key={survey.id}
                  >
                    <Box className={classes.QuestionRow}>
                      <h3>
                        {survey.question.charAt(0).toUpperCase() +
                          survey.question.slice(1)}
                      </h3>
                    </Box>
                    {survey.question_options?.length > 0 ? (
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Radio
                                  color="primary"
                                  selected={responseArray.find((i) => {
                                    if (survey.id == i.id) {
                                      i.answer == "yes" ||
                                        i.answer == "yes_option";
                                    }
                                  })}
                                  onChange={(e) => {
                                    if (
                                      survey.question_order == 3 ||
                                      survey.question_order == 4
                                    ) {
                                      handleChangeSelected(e, survey.id, true);
                                    } else {
                                      handleChangeSelected(e, survey.id);
                                    }
                                  }}
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={
                                <Radio
                                  color="primary"
                                  selected={responseArray.find((i) => {
                                    if (survey.id == i.id) {
                                      i.answer == "no";
                                    }
                                  })}
                                  onChange={(e) =>
                                    handleChangeSelected(e, survey.id)
                                  }
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    ) : (
                      <TextField
                        className={classes.input}
                        placeholder="Your answer"
                        type="text"
                        onChange={(e) => handleTextInput(e, survey.id)}
                      />
                    )}
                    <Box>
                      {dropOptions[survey.id] &&
                        (survey.question_order == 3 ||
                          survey.question_order == 4) && (
                          <select
                            key={survey.id}
                            onChange={(e) =>
                              handleDropDownChangeSelected(e, survey.id)
                            }
                          >
                            <option>--select option--</option>
                            <option value={survey.dropdown_value[0]}>
                              {survey.dropdown_value[0]
                                .charAt(0)
                                .toUpperCase() +
                                survey.dropdown_value[0].slice(1)}
                            </option>
                            <option value={survey.dropdown_value[1]}>
                              {survey.dropdown_value[1]
                                .charAt(0)
                                .toUpperCase() +
                                survey.dropdown_value[1].slice(1)}
                            </option>
                            <option value={survey.dropdown_value[2]}>
                              {survey.dropdown_value[2]
                                .charAt(0)
                                .toUpperCase() +
                                survey.dropdown_value[2].slice(1)}
                            </option>
                          </select>
                        )}
                    </Box>
                  </ListItem>
                ))
              : null}
          </List>

          {answerError ? (
            <p
              style={{
                color: "#dc3545",
                fontSize: ".875em",
                marginTop: "0.25rem",
                textAlign: "right",
              }}
            >
              All answers are mandatory
            </p>
          ) : (
            <></>
          )}

          <Box>
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={onUserSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Survey;
const useStyles = makeStyles(() => ({
  Survey: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    padding: "30px 5% 0",
    "& a": {
      textDecoration: "none",
    },
    "& .MuiListItem-button:hover": {
      background: "rgba(47, 153, 241, 0.1)",
    },
  },
  loginbtn: {
    background: "linear-gradient(90deg, #2F99F1 0%, #192853 100%)",
    padding: "0 20px",
    width: "94%",
    height: "50px",
    borderRadius: "50px",
    color: "#fff",
    marginTop: "0",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "45px",
    width: "100%",
    marginBottom: "10px",
    marginTop: 10,
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "45px",
    },
    "& .MuiInputBase-input": {
      height: "45px",
      borderRadius: "10px",
      background: "#fff",
      padding: "0 15px",
      border: "1px solid #2F99F1",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  QuestionRow: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: "600",
      marginRight: 10,
      margin: 0,
      color: "#2F99F1",
    },
  },
  surveyList: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    borderRadius: 10,
    paddingLeft: 10,
    marginBottom: 15,
    "& h3": {
      fontSize: 18,
      color: "#192853",
      margin: 0,
      fontFamily: "Poppins",
      fontWeight: "400",
    },
    "& h4": {
      fontSize: 20,
      color: "#2F99F1",
      margin: 0,
      fontFamily: "Poppins",
      lineHeight: "22px",
      fontWeight: "400",
      textAlign: "center",
    },
    "& p": {
      color: "#5C5C5C",
      fontSize: 14,
      margin: 0,
      fontFamily: "Poppins",
      textAlign: "center",
    },
  },

  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
  },
}));
