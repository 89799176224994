import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import nav from '../Images/nav.svg';
import { Box } from '@material-ui/core';
import { BiDownArrowAlt } from 'react-icons/bi';

const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
});

function GraphRange(props) {
    const classes = useStyles();
    
    return (
    <Box style={{float:'left', width: props.width}}>
        
        <Box style={{width: '100%', height: props.height, backgroundColor: props.color}}>
               
        </Box>
        <Box style={{width: props.width}}>
            <span style={{textAlign: 'left', color: '#aeaeae', fontSize:'12px'}}>{props.start}</span>  
        </Box> 
    </Box>
    );

}

export default GraphRange;
