import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import risk from '../Images/risk.png';
import heart from '../Images/heart.png';
import blood from '../Images/blood.png';
import hrv from '../Images/hrv.png';
import cardiac from '../Images/cardiac.png';
import hearthealth from '../Images/heart-health.svg';
import hearthealth2 from '../Images/heart-health-disable.svg';
import loaderGif from "../Images/loader.svg";
import claim from "../Images/animation_200_l3ydtvyw.gif";
import rpm from '../Images/rpm_ad.png';
import bodyvitals from '../Images/body-vitals.svg';
import bodyvitals2 from '../Images/body-vitals-disable.svg';
import physio from '../Images/physio-fitness.svg';
import physio2 from '../Images/physio-fitness-disable.svg';
import stress from '../Images/stress-ans.svg';
import stress2 from '../Images/stress-ans-disable.svg';
import tutorial from '../Images/tutorial.png';
import report from '../Images/report.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import Tutorials from '../Images/tutorials.svg';
import Nav from '../Component/Nav';
import GaugeChart from 'react-gauge-chart'
import moment from "moment";
import tutorialVideo from '../Images/faceTutorial.mp4';
import { apiAxios, apiAxios2 } from "../Util/ApiAxios"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from 'react-motion-animate'

function Dashboard(props) {
    const classes = useStyles();
    const [greetings, setGreetings] = React.useState("Welcome,");
    const [userDetails, setUserDetails] = React.useState("");

    const [showLoader, setShowLoader] = React.useState(false);
    const [latestData, setLatestData] = React.useState("");

    const user = JSON.parse(localStorage.getItem("user_details")); 

    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
          videoEl.current &&
          videoEl.current.play().catch((error) => {
            console.error("Error attempting to play", error);
          });
      };

  const startScan = (flag) => {

    if(flag == 1){
        props.history.push("/face-scan");
    }
    else{
        props.history.push("/finger-scan");
    }

  };  

  useEffect(() => {


    setShowLoader(true);

    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
        setGreetings("Good Morning");
    } else if (curHr < 18) {
        setGreetings("Good Afternoon");
    } else {
        setGreetings("Good Evening");
    }

    //setUserDetails( JSON.parse(localStorage.getItem("user_details")) );

    let ud = JSON.parse(localStorage.getItem("user_details"));

    if(ud){
        getProfile();

        getSurvey();
        

        if(localStorage.getItem("scan_result")){
            localStorage.removeItem("scan_result");
        }

    }

    

  }, []);  

  const startScanClick = () => {
      window.location.href="/select-option";
  }

  const getProfile = () =>{

    let data = {
        
      };


        apiAxios2
        .post("/users/profile/view", data, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": user.id_token
            },
        })
        .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
            
                localStorage.setItem("height", response.data.profile.height);
                localStorage.setItem("weight", response.data.profile.weight);

            localStorage.setItem("emp_id", response.data.profile.employee_id);
            let ud = JSON.parse(localStorage.getItem("user_details"));
            ud.first_name = response.data.profile.first_name;
            ud.last_name = response.data.profile.last_name;
            localStorage.setItem("user_details", JSON.stringify(ud));

            if(response.data.profile.first_name == "" || response.data.profile.last_name == null){
                props.history.push("/basic-details");
            }
            else {
                getView();
                setShowLoader(false);
            }
            

            } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setShowLoader(false);
            }
        })
        .catch((error) => {
            toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setShowLoader(false);
        });


  }

  const getSurvey = () =>{
    
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
        table_config_rows_per_page: 10,
        table_config_page_no: 1,
      };
      apiAxios2
        .post("/users/questions/list", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: userDetails.id_token,
          },
        })
        .then((response) => {
          let data = response.data.response.response_packages;
          
          if(data[0].question_answers == "" || data[0].question_answers == null){
            props.history.push("/survey");
          }
          
        })
        .catch((error) => {
          console.log(error);
        });

  }

  const getView = () =>{

    let dt = new Date();

    let data = {
        action: "list_scan_UL",
        employee_id: localStorage.getItem("emp_id"),
        table_config_rows_per_page: 1,
        table_config_page_no: 1,
      };


        apiAxios2
        .post("/users/sdk/test_api_private", data, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": user.id_token
            },
        })
        .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
            
                setLatestData(response.data.result[0]);
                localStorage.setItem("latest_scan", response.data.result[0].scan_id);

            } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
             //setShowLoader(false);
            }
        })
        .catch((error) => {
            toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            //setShowLoader(false);
        });


  }
  
    
  return <>
      { showLoader ?  
        <Box style={{ width: "100%", margin:'80% 0 0', textAlign: "center",height:'100%',display:'flex',justifyContent:'center',alignItems:'center' }}>
        <img
            src={loaderGif}
            alt="loader"
            style={{ width: "80px", height: "80px" }}
        />
        </Box>  
        :
        <Box className={classes.Scan}>  
            <Box className={classes.logo}>

                {user.first_name ?
                <Box style={{width: '75%',textAlign:'left'}}>
                    <p className={classes.greeting}>{greetings}</p>
                    {(user.first_name.length > 10) ?
                    <h6 className={classes.name} style={{color:'#2f99f1',marginBottom:20,}}>{user.first_name.substring(0,10)+ "..."}</h6>
                    :
                    <h6 className={classes.name} style={{color:'#2f99f1',marginBottom:20,}}>{user.first_name}</h6>
                    }
                </Box>
                :
                <></>
                }
                <Box>
                <Nav />
                </Box>
            </Box>
            <Box style={{display:'flex',flexDirection:'column', height:'85%'}}> 
                <Box className={classes.scanbox} style={{display:'flex',flexDirection:'column'}}>
                    <Box className={classes.scanleft}>
                        <h6 className={classes.name} style={{lineHeight:'1em',marginBottom:'2%',color:'rgb(51 73 139)',fontSize:20,}}>Monitor Your Health</h6>
                        <p style={{lineHeight:'20px',color:'#616b88',fontFamily:'Poppins',fontSize:15,}}>Do a self health-check in less than<br/> one minute.</p> 
                    </Box>
                    <Box className={classes.scanright}>
                            <Button size="large" className={classes.loginbtn} onClick={startScanClick}>
                                Start A Scan
                            </Button>
                    </Box>     
                </Box>
                
                {latestData ?
                <Box className={classes.healthscorebox}>
                    
                    <Box className={classes.healthscoreboxleft}>
                        <GaugeChart id="gauge-chart3" 
                            nrOfLevels={4} 
                            arcsLength={[0.2, 0.2, 0.2, 0.4]} 
                            colors={["#32b509", "#a0dd0c", "#eea508", "#f31e2e"]} 
                            arcWidth={0.3} 
                            percent={(latestData.health_risk_score/100)} 
                            textColor="#000"
                            needleColor="#aeaeae"
                            />
                    </Box>
                    
                    <Box className={classes.healthscoreboxright}>
                        <span style={{fontFamily:'Poppins',}}>Your Health <br/>Risk Score</span>
                        {latestData.health_risk_score == "--" ? 
                        <></>
                        :     
                        <Box style={{width:'100%', textAlign: 'left',fontFamily:'Poppins'}}>
                        {(latestData.health_risk_score <= 20 ) ?
                        <h3 style={{color: '#32b509', margin:'2% 0',fontFamily:'Poppins',}}>LOW</h3>
                        :
                        (latestData.health_risk_score > 20 && latestData.health_risk_score <= 40 ) ?
                          <h3 style={{color: '#a0dd0c', margin:'2% 0',fontFamily:'Poppins'}}>MEDIUM</h3>
                          :
                          (latestData.health_risk_score > 40 && latestData.health_risk_score <= 60 ) ?
                            <h3 style={{color: '#eea508',fontFamily:'Poppins'}}>HIGH</h3>
                            :
                            <h3 style={{color: '#f31e2e',fontFamily:'Poppins'}}>VERY HIGH</h3>
                        }
                        </Box>
                        }
                    </Box>
                </Box>  
                :
                <></>
                }

                {/* <Box className={classes.ScanCamera} style={{height: 'auto', textAlign:'left'}}>
                    <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'5%', marginBottom:'2%', fontSize: '1rem', fontWeight: '400' }}>How it works?</h6>
                    <video
                    style={{ maxWidth: "100%", width: "800px", margin: "2% auto", borderRadius: '20px'  }}
                    playsInline
                    loop
                    muted
                    controls
                    alt="All the devices"
                    src={tutorialVideo+"#t=0.001"}
                    ref={videoEl}
                    />
                </Box> */}
<MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.1}
            speed={1}>
                <Box className={classes.scanbox} style={{height:'auto'}}>
                    <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                        {/* <Link to="/report">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={report} alt="report" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link> */}
                        
                            <Box className={classes.tutorialbox} style={{marginTop:'3%'}}>
                                <Box style={{width:'55%'}}><img src={Tutorials} alt="tutorial" style={{width: '100%' }} /></Box>
                                <Box>
                                    <Link to="/tutorial-list"><Button size="large" className={classes.TutorialBtn}>
                                    Tutorial
                                 </Button>
                            </Link>
                                </Box>
                            </Box>
                       
                        
                    </Box>
                </Box>
                </MotionAnimate>
                {/* <Box className={classes.scanbox} style={{height:'25%'}}>
                    <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                        <h6 className={classes.name} style={{lineHeight:'1em',marginTop:'5%', marginBottom:'2%' }}>Vitals History</h6>
                            <Box className={classes.histIcon}>
                                <img src={heart} alt="health risk" style={{padding:'10%', width: '50%' }} />
                                <h5 className={classes.histText}>Key Vitals</h5>
                            </Box>
                            <Box className={classes.histIcon}>
                                <img src={blood} alt="health risk" style={{padding:'10%', width: '50%' }} />
                                <h5 className={classes.histText}>Physiological</h5>
                            </Box>
                            <Box className={classes.histIcon}>
                                <img src={cardiac} alt="health risk" style={{padding:'10%', width: '50%' }} />
                                <h5 className={classes.histText}>Cardiac</h5>
                            </Box>
                            <Box className={classes.histIcon}>
                                <img src={hrv} alt="health risk" style={{padding:'10%', width: '50%' }} />
                                <h5 className={classes.histText}>HRV & Stress</h5>
                            </Box>
                    </Box>
                </Box> */}

                { latestData ? 
                <Box className={classes.scanbox} style={{height:'25%'}}>
                    <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                        <h6 className={classes.name} style={{lineHeight:'1em',marginTop:0, marginBottom:20, fontSize:16, fontWeight: '600',fontFamily:'Poppins',color:'rgb(51, 73, 139)' }}>My Activities</h6>
                        <Box className={classes.VitalsRow} style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                        <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.2}
            speed={1}>
                            <Box className={classes.vitalscol}>
                            <Link to="/heart">
                             <Box className={classes.vitalsicon}>   
                            <img src={hearthealth} alt="heart health" style={{width:'80%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Heart <span style={{color:'#2f99f1'}}>Health</span></h4>
                            </Link>
                            </Box>
                            </MotionAnimate>
                            <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.3}
            speed={1}>
                            <Box className={classes.vitalscol}>
                            <Link to="/vitals">
                             <Box className={classes.vitalsicon}>   
                            <img src={bodyvitals} alt="body vitals" style={{width:'70%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Body <span style={{color:'#2f99f1'}}>Vitals</span></h4>
                            </Link>
                            </Box>
                            </MotionAnimate>
                            <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.4}
            speed={1}>
                            <Box className={classes.vitalscol}>
                            <Link to="/physiological">
                             <Box className={classes.vitalsicon}>   
                            <img src={physio} alt="physio fitness" style={{width:'70%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Physio <span style={{color:'#2f99f1'}}>Fitness</span></h4>
                            </Link>
                            </Box>
                            </MotionAnimate>
                            <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.5}
            speed={1}>
                            <Box className={classes.vitalscol}>
                            <Link to="/stress">
                             <Box className={classes.vitalsicon}>   
                            <img src={stress} alt="stress" style={{width:'70%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Stress <span style={{color:'#2f99f1'}}>Index</span></h4>
                            </Link>
                            </Box>
                            </MotionAnimate>
                        </Box>
                        {/* <Link to="/vitals">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={bodyvitals} alt="key vitals" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link>
                        <Link to="/heart">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={hearthealth} alt="heart health" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link>
                        <Link to="/physiological">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={physio} alt="phyiological" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link>
                        <Link to="/stress">
                            <Box className={classes.scanbox} style={{marginTop:'3%'}}>
                                <img src={stress} alt="heart health" style={{borderRadius:'10px', width: '100%' }} />
                            </Box>
                        </Link> */}
                        
                    </Box>
                </Box>
                :
                <Box className={classes.scanbox} style={{height:'25%'}}>
                    <Box className={classes.scanboxleft} style={{width:'100%', margin:'5% 0',padding:'0'}}>
                    <h6 className={classes.name} style={{lineHeight:'1em',marginTop:0, marginBottom:20, fontSize:16, fontWeight: '600',fontFamily:'Poppins',color:'rgb(51, 73, 139)' }}>My Activities</h6>
                        
                    <Box className={classes.VitalsRow2} style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                    <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.2}
            speed={1}>
                            <Box className={classes.vitalscol}>
                             <Box className={classes.vitalsicon2}>   
                            <img src={hearthealth2} alt="heart health" style={{width:'80%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Heart <span style={{color:'#84878f'}}>Health</span></h4>
                            
                            </Box>
                            </MotionAnimate>
                            <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.3}
            speed={1}>
                            <Box className={classes.vitalscol}>
                             <Box className={classes.vitalsicon2}>   
                            <img src={bodyvitals2} alt="body vitals" style={{width:'70%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Body <span style={{color:'#84878f'}}>Vitals</span></h4>
                            
                            </Box>
                            </MotionAnimate>
                            <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.4}
            speed={1}>
                            <Box className={classes.vitalscol}>
                            
                             <Box className={classes.vitalsicon2}>   
                            <img src={physio2} alt="physio fitness" style={{width:'70%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Physio <span style={{color:'#84878f'}}>Fitness</span></h4>
                            
                            </Box>
                            </MotionAnimate>
                            <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.5}
            speed={1}>
                            <Box className={classes.vitalscol}>
                            
                             <Box className={classes.vitalsicon2}>   
                            <img src={stress2} alt="stress" style={{width:'70%'}} />
                            </Box>
                            <h4 style={{fontFamily:'Poppins',fontSize:16,margin:0,fontWeight:'600',color:'#172652'}}>Stress <span style={{color:'#84878f'}}>Index</span></h4>
                           
                            </Box>
                            </MotionAnimate>
                        </Box>
                        
                        
                    </Box>
                </Box>
                }

                
            </Box>
        </Box>
        }  
  </>;
}

export default Dashboard;
const useStyles = makeStyles(() => ({
    Scan:{
        display:'flex',
        height:'100vh',
        padding:'0 7.5%',
        left: 0,
        right:'0',
        background: '#fff',
        flexDirection:'column',
        '& a':{
            textDecoration:'none'
        }
    },
    VitalsRow:{
        '&>div':{
            width:'47%',
            background:'#fff',
            borderRadius:15,
            boxShadow:'1px 1px 10px 1px rgba(23,38,82,0.1)',
            padding:'15px 0px',
            textAlign:'center',
            marginBottom:20,
        }
    },
    VitalsRow2:{
        '&>div':{
            width:'47%',
            background:'#fff',
            borderRadius:15,
            boxShadow:'1px 1px 10px 1px rgba(23,38,82,0.1)',
            padding:'15px 0px',
            textAlign:'center',
            marginBottom:20,
        }
    },
    vitalscol:{
        width:'100%',
        // background:'#fff',
        // borderRadius:15,
        // boxShadow:'1px 1px 10px 1px rgba(23,38,82,0.1)',
        // padding:'15px 0px',
        // textAlign:'center',
        // marginBottom:20,
    },
    healthscorebox:{
        width:'100%',
        background: '#ebf6ff',
        borderRadius: '10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:'15px 0 10px'
    },
    scanbox:{
        width:'100%',
    },
    tutorialbox:{
        width:'100%',
        background:'#eef5fb',
        borderRadius:10,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        
    },
    vitalsicon:{
        width:60,
        height:60,
        borderRadius:'50%',
        background: 'linear-gradient(#d7edff 0%, #fff 92.7%)',
        margin:'0px auto',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    vitalsicon2:{
        width:60,
        height:60,
        borderRadius:'50%',
        background: 'linear-gradient(#cecece 0%, #fff 92.7%)',
        margin:'0px auto',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    healthscoreboxleft:{
        width:'60%',
        float:'left',
        height:'100%',
    },
    histIcon:{
        width: '25%',
        textAlign:'center',
        float: 'left'
    },
    histText:{
        fontFamily: 'Poppins',
        fontSize: '0.8em',
        fontWeight: '300',
        margin: '0'
    },
    scanboxleft:{
        width:'45%',
        float:'left',
        textAlign:'left',
        '& p':{
            fontSize: '12px',
            color: '#000',
            fontFamily:'Poppins',
        }
    },
    scanleft:{
        width:'100%'
    },
    healthscoreboxright:{
        width:'40%',
        color:'#1a2e5c',
        float:'left',
        textAlign:'left',
        display:'flex',
        justifyContent:'center',
        flexDirection:'column'
    },
    scanboxright:{
        width:'55%',
        float:'left',
        textAlign:'center',
    },
    riskbar:{
        padding:'10%', 
        height: '2.5em', 
        marginTop: '15%', 
        borderRadius:'100%', 
        border: '3px solid #fff'
    },
    subScan:{
        fontSize:'0.7em',
        color: '#5c6374',
        margin: '0',
        padding: '0 10%',
        fontFamily:'Poppins',
    },
    optionIcon:{
       width: '100%',
       textAlign: 'center',
       height: '50%',
       '& h4':{
        fontFamily:'Poppins',
        color:'#192853',
        fontWeight:'400',
        fontSize:16,
        marginTop:10,
        marginBottom:10,
        }, 
    },
    ScanInfo:{
        width:'100%',
        background:'linear-gradient(180deg, #2F99F1 -92.7%, #192853 92.7%)',
        height:'38vh',
        '& p':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.7)',
            fontWeight:'400',
            fontSize:12,
            padding:'0 20px',
            marginTop:0,
            marginBottom:30,
            lineHeight:1.6,
        },
        '& h4':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.8)',
            fontWeight:'400',
            fontSize:16,
            marginTop:20,
            marginBottom:10,
        },
        '& h3':{
            fontSize:16,
            fontFamily:'Poppins',
            color:'#fff',
            fontWeight:'400',
            marginTop:40,
            marginBottom:0,
            '& span':{
                fontSize:24,
                fontWeight:'600',
                color:'#2F99F1',
                marginRight:5,
            }
        }
    },
    ScanCamera:{
        width:'100%',
        height:'49.7vh'
    },
    logo:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        height:'15%',
        marginBottom:10,
    },
    TutorialBtn:{
        fontFamily:'Poppins',
        fontSize:14,
        color:'#2f99f1',
        textTransform:'capitalize',
        borderRadius:50,
        padding:'7px 25px',
        border:'1px #2f99f1 solid',
        background:'#fff',
        marginRight:20,
        '&:hover':{
            background:'#2f99f1',
            color:'#fff'
        }
    },
    CancelBtn:{
        fontFamily:'Poppins',
        fontSize:16,
        color:'#2F99F1',
        textTransform:'capitalize',
        borderRadius:50,
        padding:'5px 20px'
    },
    
    loginbtn:{
        background:'#1e3063',
        padding:'0 20px',
        width:'70%',
        height:'50px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
        textTransform:'capitalize',
        boxShadow: '0 0 0 0 #2680db24',
        animation: 'pulse 1.5s infinite',
        marginBottom:30,
        fontFamily:'Poppins',
        fontSize:16,
        
    },
    name:{
        margin: 0,
        fontSize: '18px',
        fontFamily:'Poppins',
        fontWeight: '600',
        color:'#283b72'
    },
    greeting:{
        fontWeight: '400',
        color: '#6393bd',
        marginBottom: 0,
        fontFamily:'Poppins',
        fontSize:18,
        marginBottom:0,
        marginTop:40,
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(.9)',
          },
          '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 50px #2680db24',
          },
        '100%': {
            transform: 'scale(.9)',
            boxShadow: '0 0 0 0 #2680db24',
          }
    }

   }));