
export const insightsList = () => {
    var status = [
    {   "video":"https://webmd-a.akamaihd.net/delivery/delivery/ba/72/ba72b653-2738-47f2-8ff3-aecd83786ad8/wbz-high-blood-pressure-treatment_750k.mp4#t=0.1",
        "content":"High blood pressure is now classified as a systolic blood pressure greater than 130 and diastolic over 80.To prevent high blood pressure, everyone should be encouraged to make lifestyle modifications, such as eating a healthier diet, quitting smoking, and getting more exercise. Treatment with medication is recommended to lower blood pressure to less than 130/80 in people older than age 65 and those with risk factors such as diabetes and high cholesterol.Treating high blood pressure involves lifestyle changes and possibly drug therapy.",
        "source": "webMD"
    },
    {   "video":"https://webmd-a.akamaihd.net/delivery/delivery/a2/ab/a2ab420a-64b5-4ea1-a7e3-eba67ba387bc/wbz-low-blood-pressure-treatments_750k.mp4#t=0.1",
        "content":"Symptoms of dizziness and lightheadedness when you stand up from sitting or lying down -- with a decrease in your blood pressure -- may indicate a condition called postural hypotension. A wide range of underlying conditions may also cause your symptoms. It's important to identify the cause of low blood pressure so appropriate treatment can be given.",
        "source": "webMD"
    },
    {   "video": "https://webmd-a.akamaihd.net/delivery/delivery/bd/80/bd8003bc-c612-4730-9073-10d7d5e98630/wbz-symptoms-of-heart-disease_750k.mp4#t=0.1",
        "content":"A better number to aim for during exercise is your target heart rate, or a safe percentage of your maximum heart rate. During activities of moderate intensity, the AHA says your target heart rate should be 50-70% of your maximum heart rate, while during vigorous physical activity, it should be 70-85% of your max.",
        "source": "WebMD"
    },
    {   "video": "https://webmd-a.akamaihd.net/delivery/delivery/f8/99/f8994320-da11-4572-ab08-090c4f428ab6/basics-bmi_750k.mp4#t=0.1",
        "content":"To reduce the risk of obesity-related diseases, people with BMIs of 25-29.9 (overweight) and 30-34.9 (level 1 obese) should have waist sizes no more than 35 inches for women and 40 inches for men (for people with BMIs over 35, the waist measurement is not a valid marker of risk factors).",
        "source": "WebMD"
    },
    {   "video": "https://webmd-a.akamaihd.net/delivery/delivery/aws/b8/5d/b85d343b-5e32-3bce-9223-3a92fe0913f9/091e9c5e81f438f9_vo-feature-exploring-sound-baths_750k.mp4",
        "content":"Stress is the body's reaction to harmful situations -- whether they’re real or perceived. When you feel threatened, a chemical reaction occurs in your body that allows you to act in a way to prevent injury. This reaction is known as fight-or-flight, or the stress response. During stress response, your heart rate increases, breathing quickens, muscles tighten, and blood pressure rises. You’ve gotten ready to act. It is how you protect yourself.",
        "source": "WebMD"
    }
    ]; 
    return status;
  };

  export const dateFilter = () => {
    var status = [
        {   "label" : "Daily",
            "value" : "daily"
        },
        {   "label" : "Weekly",
            "value" : "weekly"
        },
        {   "label" : "Monthly",
            "value" : "monthly"
        },
        {   "label" : "Yearly",
            "value" : "yearly"
        },
    ]; 
    return status;
  };
