import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import demo from "../Images/login.svg";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import Countdown from "react-countdown";

import loader from "../Images/loader.svg";

import { apiAxios, apiAxios2, API_KEY } from "../Util/ApiAxios";
import { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from "react-motion-animate";

const Completionist = () => (
  <span
    style={{
      fontSize: 16,
      fontFamily: "Poppins",
      color: "#ff0000",
      fontWeight: "500",
    }}
  >
    Time Out
  </span>
);
// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {

  let timer = JSON.parse(localStorage.getItem("timer"));
  if(timer == null){
    localStorage.removeItem("end_date")
  }
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {

     if(timer == true){
    // Render a countdown
    return (
      <span style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: "500" }}>
        {minutes}:{seconds < 10 ?`0${seconds}` : seconds}
      </span>
    );
     }else {
       
      return <Completionist />;
       
     }
  }
};

const getLocalStorageValue = (s) => localStorage.getItem(s);

function OTP(props) {
  const classes = useStyles();

  const [otp, setOtp] = React.useState("");
  const [otpError, setOtpError] = React.useState(false);

  const [showLoader, setShowLoader] = React.useState(false);

  const [resendOTP, setResendOTP] = React.useState(false);

  // countdown timer
  const [data, setData] = useState({ date: Date.now(), delay: 100000 });
  const wantedDelay = 100000;

  useEffect(() => {
    const savedDate = getLocalStorageValue("end_date");
    let result = JSON.parse(localStorage.getItem("sendOTP"));

    // otp sent msg on page first page load and after timeout
    if (localStorage.getItem("phone") && result == true) {
      toast.success("OTP Sent Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    let timer = JSON.parse(localStorage.getItem("timer"));
    if (timer == null){
      setResendOTP(true)
    }
    // countdown timer
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      if (delta > wantedDelay) {
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        setData({ date: currentTime, delay: delta });
      }
    }

    localStorage.removeItem("sendOTP")
  }, [resendOTP]);

  // useEffect(() => {

  //   if (localStorage.getItem("phone")) {
  //     toast.success("OTP Sent Successfully", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }, []);

  function onResend() {
    // setResendOTP(false);
    let data = {
      action: "send",
      mobile_no: localStorage.getItem("phone"),
      channel_id: "login_mobile",
      email: "",
      api_key: localStorage.getItem("api_key"),
    };

    // localStorage.setItem("api_key", clientCode);

    apiAxios2
      .post("/auth/send-otp", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setResendOTP(false)
          let timerFlag = true
          localStorage.setItem("timer", JSON.stringify(timerFlag));
          // props.history.push("/verify");
          window.location.reload();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowLoader(false);
      });
  }

  function onUserSubmit(e) {
    e.preventDefault();

    let isValid = true;

    localStorage.removeItem("Token");

    if (otp.length == 6) {
      setOtpError(false);
    } else {
      setOtpError(true);
      isValid = false;
    }

    if (isValid) {
      setShowLoader(true);
      

      let data = {
        mobile_no: localStorage.getItem("phone"),
        password: "",
        login_type: "session",
        login_method: "otp_mobile",
        email: "",
        otp: otp,
        api_key: localStorage.getItem("api_key"),
      };

      apiAxios2
        .post("/auth/sign-in", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
              
            localStorage.setItem(
              "user_details",
              JSON.stringify(response.data.user)
            );
            localStorage.removeItem("phone");
            localStorage.removeItem("end_date")

            //let ud = localStorage.getItem("user_details");
            if (
              response.data.user.first_name == "" ||
              response.data.user.first_name == null
            ) {
              props.history.push("/update-profile");
            }

            props.history.push("/dashboard");
            
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
            
          }
          
        })
        .catch((error) => {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        });

       setOtp("")
    }
    
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box className={classes.Login}>
        <Box className={classes.logo}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "70%", padding: "15% 0 5% 0" }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "80%",
          }}
        >
          <Box style={{ width: "100%", height: "55%" }}>
            <img src={demo} alt="Demo Image" style={{ width: "80%" }} />
          </Box>

          <Box className={classes.loginform}>
            <p
              style={{
                fontSize: 18,
                color: "rgb(71 130 177)",
                textAlign: "left",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              Verify
              <span style={{ float: "right", color: "rgb(162 181 209)" }}>
                {/* <Countdown
              date={Date.now() + 100000}
              renderer={renderer}
            /> */}

                <Countdown
                  date={data.date + data.delay}
                  renderer={renderer}
                  onStart={(delta) => {
                    //Save the end date
                    if (localStorage.getItem("end_date") == null)
                      localStorage.setItem(
                        "end_date",
                        JSON.stringify(data.date + data.delay)
                      );
                      setResendOTP(false);
                  }}
                  onComplete={() => {
                    if (localStorage.getItem("end_date") != null)
                      localStorage.removeItem("end_date");
                      setResendOTP(true);
                      localStorage.removeItem("timer")
                  }}

                />
              </span>
            </p>

           
              <form>
                <TextField
                  className={classes.input}
                  placeholder="Enter 6 Digit OTP"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    if (e.target.value.length > 6) {
                      setOtpError(true);
                    } else {
                      setOtpError(false);
                    }
                  }}
                  type="number"
                />
                {otpError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Please enter a 6 digits OTP.
                  </p>
                ) : (
                  <></>
                )}
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Link to="/" style={{width:'48%',marginRight:'4%'}}>
                    <Button className={classes.Cancelbtn} onClick={()=>localStorage.removeItem("end_date")}>Cancel</Button>
                  </Link>
                  {showLoader ? (
                    <>
                      <img
                        src={loader}
                        alt="loader"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </>
                  ) : resendOTP ? (
                    <Button
                      size="large"
                      className={classes.loginbtn}
                      onClick={onResend}
                    >
                      Resend
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      className={classes.loginbtn}
                      type="submit"
                      onClick={onUserSubmit}
                    >
                      Verify
                    </Button>
                  )}
                </Box>
              </form>
            
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OTP;
const useStyles = makeStyles(() => ({
  Login: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    left: 0,
    right: 0,
    padding: "0 30px 0",
    "& a": {
      textDecoration: "none",
    },
  },

  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
    marginBottom: "30px",
  },
  loginform: {
    width: "100%",
    height: "45%",
    paddingTop: "5%",
    paddingBottom: "10%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      background: "#fff",
      padding: "0 15px",
      border: "1px solid #929aa1",
    },
    "& .MuiInput-input:focus": {
      border: "1px #2f99f1 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  loginbtn: {
    background: "#1e3063",
    padding: "0 20px",
    width: "48%",
    height: "50px",
    borderRadius: "50px",
    color: "#fff",
    marginTop: "0",
    textTransform: "capitalize",
    fontFamily:'Poppins',
    "&:hover": {
      background: "#333",
    },
  },
  logo: {
    height: "20%",
  },
  Cancelbtn: {
    color: "#747b91",
    fontSize: 16,
    padding: "0 20px",
    width: "100%",
    height: "50px",
    borderRadius: "50px",
    textTransform: "capitalize",
    fontFamily:'Poppins',
    border:'1px #747b91 solid',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
}));
