import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import HeartRate from '../Images/SDNN1.svg';
import BloodPressure from '../Images/Carediac-output.svg';
import RespirationRate from '../Images/mean-arterial-pressure.svg';
import OxygenSaturation from '../Images/Vo2max.svg';
import risk from '../Images/risk.png';
import heart from '../Images/heart.png';
import blood from '../Images/blood.png';
import hrv from '../Images/hrv.png';
import cardiac from '../Images/cardiac.png';
import hearthealth from '../Images/heart_health2.png';
import rpm from '../Images/rpm_ad.png';
import bodyvitals from '../Images/body_vitals.png';
import physio from '../Images/physio.png';
import stress from '../Images/stress.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import Nav from '../Component/Nav';
import GaugeChart from 'react-gauge-chart'
import moment from "moment";
import { BiArrowBack, BiChevronRight } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import ChartData from '../Component/ChartData';
import { insightsList } from "../Util/KeyInsights";
import { apiAxios, apiAxios2 } from "../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MotionAnimate } from 'react-motion-animate';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

function HeartHealth(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [greetings, setGreetings] = React.useState("Welcome,");
    const [userDetails, setUserDetails] = React.useState("");

    const [insights, setInsights] = React.useState([]);

    const [latestData, setLatestData] = React.useState("");

    const user = JSON.parse(localStorage.getItem("user_details"));



    useEffect(() => {

        var today = new Date();
        var curHr = today.getHours();

        if (curHr < 12) {
            setGreetings("Good Morning");
        } else if (curHr < 18) {
            setGreetings("Good Afternoon");
        } else {
            setGreetings("Good Evening");
        }

        let ins_arr = insightsList();
        setInsights(ins_arr);
        //setUserDetails( JSON.parse(localStorage.getItem("user_details")) );

        //getProfile();
        getView();


    }, []);


    const getView = () => {

        let dt = new Date();

        let data = {
            action: "list_scan_UL",
            employee_id: localStorage.getItem("emp_id"),
            table_config_rows_per_page: 1,
            table_config_page_no: 1,
        };


        apiAxios2
            .post("/users/sdk/test_api_private", data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": user.id_token
                },
            })
            .then((response) => {
                console.log(response.data);
                if (response.data.statusCode == 200) {

                    setLatestData(response.data.result[0]);

                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    //setShowLoader(false);
                }
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                //setShowLoader(false);
            });


    }


    return <>
        <Box className={classes.Scan}>
            <Box className={classes.logo} style={{ minHeight: '10%' }}>
                <Box style={{ width: '80%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <Link to="/dashboard"><BiArrowBack size="26" style={{ color: 'rgb(78 90 98)', marginTop: 5, }} /></Link> <h6 className={classes.name}><span>Heart Health</span></h6>
                </Box>
                <Box>
                    <Nav />
                </Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <Box style={{padding:'4%', textAlign: 'right'}}>
            <span>Weekly</span>           
        </Box> */}

                <Box className={classes.sliderClass}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary

                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Box className={classes.vitalsicon}>
                                    <img src={HeartRate} alt="Heart rate" style={{ width: "60%" }} />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, textAlign: 'left', width: '65%' }}>
                                    <h3 style={{ fontFamily: 'Poppins', fontSize: 16, margin: 0, fontWeight: '500', color: '#172652' }}>HRV <span style={{ color: '#2f99f1' }}>(SDNN)</span></h3>
                                    <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(171 184 199)', margin: '0 0 5px', display: 'flex', alignItems: 'center' }}><FiClock size="13" style={{ color: 'rgb(171 184 199)', marginRight: 5, }} /> <span style={{ marginTop: 3 }}>23-05-2022,  06:44 PM</span></p>
                                    <h4 style={{ margin: 0, fontFamily: 'Poppins', fontSize: 18, fontWeight: '600', color: '#2f99f1' }}>78 <spnan style={{ color: '#8f9498', fontSize: 14, fontWeight: '400' }}>ms</spnan></h4>
                                </Box>
                                <Link><BiChevronRight size="26" style={{ color: 'rgb(139 157 175)' }} /></Link>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ChartData vitals="heart_rate" range="weekly" type="heart" name="sdnn" />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary

                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Box className={classes.vitalsicon}>
                                    <img src={BloodPressure} alt="Blood Pressure" style={{ width: "60%" }} />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, textAlign: 'left', width: '65%' }}>
                                    <h3 style={{ fontFamily: 'Poppins', fontSize: 16, margin: 0, fontWeight: '500', color: '#172652' }}>Cardiac Output</h3>
                                    <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(171 184 199)', margin: '0 0 5px', display: 'flex', alignItems: 'center' }}><FiClock size="13" style={{ color: 'rgb(171 184 199)', marginRight: 5, }} /> <span style={{ marginTop: 3 }}>23-05-2022,  06:44 PM</span></p>
                                    <h4 style={{ margin: 0, fontFamily: 'Poppins', fontSize: 18, fontWeight: '600', color: '#2f99f1' }}>4.84 <spnan style={{ color: '#8f9498', fontSize: 14, fontWeight: '400' }}>ltrs</spnan></h4>
                                </Box>
                                <Link><BiChevronRight size="26" style={{ color: 'rgb(139 157 175)' }} /></Link>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ChartData vitals="bp" range="weekly" type="heart" name="co" />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary

                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Box className={classes.vitalsicon}>
                                    <img src={RespirationRate} alt="Respiration Rate" style={{ width: "60%" }} />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, textAlign: 'left', width: '65%' }}>
                                    <h3 style={{ fontFamily: 'Poppins', fontSize: 16, margin: 0, fontWeight: '500', color: '#172652' }}>Mean Arterial Pressure</h3>
                                    <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(171 184 199)', margin: '0 0 5px', display: 'flex', alignItems: 'center' }}><FiClock size="13" style={{ color: 'rgb(171 184 199)', marginRight: 5, }} /> <span style={{ marginTop: 3 }}>23-05-2022,  06:44 PM</span></p>
                                    <h4 style={{ margin: 0, fontFamily: 'Poppins', fontSize: 18, fontWeight: '600', color: '#2f99f1' }}>55.63 <spnan style={{ color: '#8f9498', fontSize: 14, fontWeight: '400' }}>mmHg</spnan></h4>
                                </Box>
                                <Link><BiChevronRight size="26" style={{ color: 'rgb(139 157 175)' }} /></Link>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ChartData vitals="bp" range="weekly" type="heart" name="map" />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary

                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Box className={classes.vitalsicon}>
                                    <img src={OxygenSaturation} alt="Oxygen Saturation" style={{ width: "60%" }} />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, textAlign: 'left', width: '65%' }}>
                                    <h3 style={{ fontFamily: 'Poppins', fontSize: 16, margin: 0, fontWeight: '500', color: '#172652' }}>VO2Max</h3>
                                    <p style={{ fontFamily: 'Poppins', fontSize: 13, color: 'rgb(171 184 199)', margin: '0 0 5px', display: 'flex', alignItems: 'center' }}><FiClock size="13" style={{ color: 'rgb(171 184 199)', marginRight: 5, }} /> <span style={{ marginTop: 3 }}>23-05-2022,  06:44 PM</span></p>
                                    <h4 style={{ margin: 0, fontFamily: 'Poppins', fontSize: 18, fontWeight: '600', color: '#2f99f1' }}>42.5 <spnan style={{ color: '#8f9498', fontSize: 14, fontWeight: '400' }}>ml/kg/min</spnan></h4>
                                </Box>
                                <Link><BiChevronRight size="26" style={{ color: 'rgb(139 157 175)' }} /></Link>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ChartData vitals="heart_rate" range="weekly" type="heart" name="vo2max" />
                        </AccordionDetails>
                    </Accordion>
                    {/* <Carousel>
                <div>
                    
                </div>
                <div>
                    
                </div>
                <div>
                    
                </div>
                <div>
                    
                </div>
            </Carousel> */}
                </Box>

                <h6
                    style={{
                        fontFamily: "Poppins",
                        fontSize: 16,
                        color: "#2F99F1",
                        marginTop: 0,
                        marginBottom: 0,
                        fontWeight: "600",
                        textAlign: "left",
                        padding: "0 1%",
                    }}
                >
                    Key Insights
                </h6>
                {(insights.length > 0) ?
                    <>
                        <video
                            src={insights[2].video}
                            id="video"
                            controls="true"
                            style={{
                                width: "100%",
                                height: "100%",
                                margin: "5% 0",
                                borderRadius: 15,
                            }}
                        ></video>
                        <p className={classes.content}>{insights[2].content}</p>

                        <p
                            className={classes.content}
                            style={{ textAlign: "right" }}
                        >
                            Source: {insights[2].source}
                        </p>
                    </>
                    :
                    <></>
                }





            </Box>
        </Box>
    </>;
}

export default HeartHealth;
const useStyles = makeStyles(() => ({
    sliderClass: {
        "& .control-dots": {
            display: "none",
        },
        "& .carousel .slider": {
            height: "auto",
        },
        "& .carousel.carousel-slider .control-arrow": {
            bottom: "initial",
            marginTop: '30%'
        },
        '& .carousel .thumbs-wrapper': {
            margin: '0'
        },
        "& .carousel .control-next.control-arrow:before": {
            borderLeft: "8px solid #333",
        },
        "& .carousel .control-prev.control-arrow:before": {
            borderRight: "8px solid #333",
        },
        "& .carousel-status": {
            display: "none",
        },
        "& .carousel .control-next.control-arrow": {
            right: 5,
        },
        "& .carousel.carousel-slider .control-arrow:hover": {
            background: "#fff",
        },
        "& .carousel .control-prev.control-arrow": {
            left: 5,
        },
        '& .MuiAccordionSummary-root': {
            boxShadow: '1px 1px 10px 0 rgba(0,0,0,0.1)',
            borderRadius: 10,
            marginBottom: 20,
        },
        '& .MuiAccordion-root:before': {
            background: '#fff'
        },
        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
        '& .MuiAccordion-rounded': {
            boxShadow: 'none'
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: 0,
        }

    },
    Scan: {
        display: 'flex',
        height: '100vh',
        padding: '0 7.5%',
        left: 0,
        right: '0',
        background: '#fff',
        flexDirection: 'column',
        '& a': {
            textDecoration: 'none'
        },
    },
    vitalsicon: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        background: 'linear-gradient(#d7edff 0%, #fff 92.7%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "400",
        padding: "0 1%",
        color: "#577397",
        fontFamily: 'Poppins'
    },
    healthscorebox: {
        width: '100%',
        background: 'linear-gradient(176deg, #eaf0f5 0%, #e9ecef 92.7%)',
        borderRadius: '10px',
        height: '25%',
    },
    scanbox: {
        height: '25%',
        width: '100%',
    },
    healthscoreboxleft: {
        width: '65%',
        float: 'left',
        height: '100%',
        paddingTop: '5%'
    },
    histIcon: {
        width: '25%',
        textAlign: 'center',
        float: 'left'
    },
    histText: {
        fontFamily: 'Poppins',
        fontSize: '0.8em',
        fontWeight: '300',
        margin: '0'
    },
    scanboxleft: {
        width: '45%',
        float: 'left',
        textAlign: 'left',
        '& p': {
            fontSize: '12px',
            color: '#000',
            fontFamily: 'Poppins',
        }
    },
    healthscoreboxright: {
        width: '35%',
        color: '#1a2e5c',
        paddingTop: '12%',
        float: 'left',
        textAlign: 'left',
        height: '100%',
    },
    scanboxright: {
        width: '55%',
        float: 'left',
        textAlign: 'center',
    },
    riskbar: {
        padding: '10%',
        height: '2.5em',
        marginTop: '15%',
        borderRadius: '100%',
        border: '3px solid #fff'
    },
    subScan: {
        fontSize: '0.7em',
        color: '#5c6374',
        margin: '0',
        padding: '0 10%',
        fontFamily: 'Poppins',
    },
    optionIcon: {
        width: '100%',
        textAlign: 'center',
        height: '50%',
        '& h4': {
            fontFamily: 'Poppins',
            color: '#192853',
            fontWeight: '400',
            fontSize: 16,
            marginTop: 10,
            marginBottom: 10,
        },
    },
    ScanInfo: {
        width: '100%',
        background: 'linear-gradient(180deg, #2F99F1 -92.7%, #192853 92.7%)',
        height: '38vh',
        '& p': {
            fontFamily: 'Poppins',
            color: 'rgba(255,255,255,0.7)',
            fontWeight: '400',
            fontSize: 12,
            padding: '0 20px',
            marginTop: 0,
            marginBottom: 30,
            lineHeight: 1.6,
        },
        '& h4': {
            fontFamily: 'Poppins',
            color: 'rgba(255,255,255,0.8)',
            fontWeight: '400',
            fontSize: 16,
            marginTop: 20,
            marginBottom: 10,
        },
        '& h3': {
            fontSize: 16,
            fontFamily: 'Poppins',
            color: '#fff',
            fontWeight: '400',
            marginTop: 40,
            marginBottom: 0,
            '& span': {
                fontSize: 24,
                fontWeight: '600',
                color: '#2F99F1',
                marginRight: 5,
            }
        }
    },
    ScanCamera: {
        width: '100%',
        height: '49.7vh'
    },
    logo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '15%',
    },
    CancelBtn: {
        fontFamily: 'Poppins',
        fontSize: 16,
        color: '#2F99F1',
        textTransform: 'capitalize',
        borderRadius: 50,
        padding: '5px 20px'
    },

    loginbtn: {
        background: 'linear-gradient(90deg, #2F99F1 0%, #192853 100%)',
        padding: '0 20px',
        width: '162px',
        height: '50px',
        borderRadius: '50px',
        color: '#fff',
        marginTop: '0',
        textTransform: 'capitalize',
        boxShadow: '0 0 0 0 #2680db24',
        animation: 'pulse 1.5s infinite',
    },
    name: {
        margin: '0 0 0 20px',
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: '600',
        color: 'rgb(51 73 139)',
    },
    greeting: {
        fontWeight: '300',
        color: '#3399f1',
        marginBottom: 0
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(.9)',
        },
        '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 50px #2680db24',
        },
        '100%': {
            transform: 'scale(.9)',
            boxShadow: '0 0 0 0 #2680db24',
        }
    }

}));