import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import doc1 from "../Images/doc1.png";
import doc2 from "../Images/doc2.png";
import doc3 from "../Images/doc3.png";
import doc4 from "../Images/doc4.png";
import complete from "../Images/GQFRr.gif";
import camera from "../Images/camera-img.png";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Nav from "../Component/Nav";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import GaugeChart from "react-gauge-chart";
import { insightsList } from "../Util/KeyInsights";
import GraphRange from "../Component/GraphRange";
import { BsFillCaretDownFill, BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { FcInfo } from "react-icons/fc";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function ScanResult(props) {
  const classes = useStyles();

  const [result, setResult] = React.useState("");
  const [data, setData] = React.useState("");
  const [insights, setInsights] = React.useState([]);

  let chart_data = [];

  useEffect(() => {
    if (localStorage.getItem("scan_result")) {
      let data = JSON.parse(localStorage.getItem("scan_result"));
      setResult(data);
      let ins_arr = insightsList();
      setInsights(ins_arr);
      chart_data.push(result.health_risk_score);
      chart_data.push(result.wellness_score);

      console.log(chart_data);

      const data2 = {
        labels: ["Risk %", "Healthy %"],
        datasets: [
          {
            data: chart_data,
            backgroundColor: ["#3399f1", "#f3f3f3"],
            borderWidth: 5,
          },
        ],
      };

      setData(data2);
    }
  }, []);

  const newScanFunc = () => {
    if (localStorage.getItem("scan_result")) {
      localStorage.removeItem("scan_result");
    }

    props.history.push({
      pathname: "/dashboard",
    });
    
  };

  const demoDoctorProfile = () => {
    window.location.href="https://demo.carenowdoc.com/ankit-saha";
  };

  const viewMoreProfile = () => {
    window.location.href="https://careplix.com/find-doctor";
  }

  const dashboardCall = () => {
    if (localStorage.getItem("scan_result")) {
      localStorage.removeItem("scan_result");
    }

    props.history.push({
      pathname: "/dashboard",
    });
  };

  const [direction, setDirection] = React.useState(2);

  const handleNav = () => {
    if(direction == 2){
        setDirection(1);
    }
    else{
        setDirection(2);
    }

  }

  return (
    <div>
      <Box className={classes.Scan}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ width: "40%", marginLeft: 25 }} />
          <Box style={{ marginRight: 15 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
            height: "88%",
          }}
        >
          <Box className={classes.ScanCamera}>
            <h5
              style={{
                fontFamily: "Poppins",
                fontSize: 18,
                color: "#2F99F1",
                marginTop: 0,
                marginBottom: 10,
                fontWeight: "400",
              }}
            >
              Results
            </h5>

            {result ? (
              <Tabs>
                    
                <TabList>
                        <Tab>Key Body Vitals</Tab>
                        <Tab>Heart Health</Tab>
                        <Tab>Physiological</Tab>
                        <Tab>Stress & ANS</Tab>
                        <Tab>Health Score</Tab>
                </TabList>
                    
                <TabPanel>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#192853",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                    }}
                  >
                    Key Body Vitals
                  </h6>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "0 35px",
                    }}
                  >
                    <Box className={classes.ScanBox}>
                      <h3>{Math.round(result.vitals.heart_rate)}</h3>
                      <h4>bpm</h4>
                      <Box
                        style={{
                          textAlign: "left",
                          marginLeft:
                            Math.round(
                              ((result.vitals.heart_rate - 20) / 160) * 100
                            ) + "%",
                        }}
                      >
                        <BsFillCaretDownFill />
                      </Box>
                      <Box style={{ width: "100%", height: "15px" }}>
                        <GraphRange
                          start={20}
                          color="#efde66"
                          width="25%"
                          height="8px"
                        />
                        <GraphRange
                          start={60}
                          color="#99c964"
                          width="25%"
                          height="8px"
                        />
                        <GraphRange
                          start={100}
                          color="#efde66"
                          width="25%"
                          height="8px"
                        />
                        <GraphRange
                          start={140}
                          end={220}
                          color="#eb5b83"
                          width="25%"
                          height="8px"
                        />
                      </Box>

                      <p>Heart Rate</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.vitals.resp_rate}</h3>
                      <h4>breathes/min</h4>
                      <Box
                        style={{
                          textAlign: "left",
                          marginLeft:
                            Math.round((result.vitals.resp_rate / 42) * 100) +
                            "%",
                        }}
                      >
                        <BsFillCaretDownFill />
                      </Box>
                      <Box style={{ width: "100%", height: "15px" }}>
                        <GraphRange
                          start={6}
                          color="#efde66"
                          width="25%"
                          height="8px"
                        />
                        <GraphRange
                          start={12}
                          color="#99c964"
                          width="25%"
                          height="8px"
                        />
                        <GraphRange
                          start={20}
                          color="#efde66"
                          width="25%"
                          height="8px"
                        />
                        <GraphRange
                          start={30}
                          color="#eb5b83"
                          width="25%"
                          height="8px"
                        />
                      </Box>
                      <p>Resp Rate</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.vitals.oxy_sat_prcnt}</h3>
                      <h4>%</h4>
                      <Box
                        style={{
                          textAlign: "left",
                          marginLeft:
                            Math.round(
                              ((result.vitals.oxy_sat_prcnt - 85) / 16) * 100
                            ) + "%",
                        }}
                      >
                        <BsFillCaretDownFill />
                      </Box>
                      <Box style={{ width: "100%", height: "15px" }}>
                        <GraphRange
                          start={0}
                          color="#eb5b83"
                          width="34%"
                          height="8px"
                        />
                        <GraphRange
                          start={90}
                          color="#efde66"
                          width="33%"
                          height="8px"
                        />
                        <GraphRange
                          start={95}
                          color="#99c964"
                          width="33%"
                          height="8px"
                        />
                      </Box>
                      <p>Oxygen Sat</p>
                    </Box>
                    
                      <Box className={classes.ScanBox}>
                        {Math.round(result.vitals.bp_sys) ? (
                          <h3>
                            {Math.round(result.vitals.bp_sys)} /{" "}
                            {Math.round(result.vitals.bp_dia)}
                          </h3>
                        ) : (
                          <h3>--</h3>
                        )}
                        <h4>mmHg</h4>

                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              Math.round(
                                ((result.vitals.bp_sys - 80) / 125) * 100
                              ) + "%",
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start={80}
                            color="#eb5b83"
                            width="14%"
                            height="8px"
                          />
                          <GraphRange
                            start={100}
                            color="#efde66"
                            width="14%"
                            height="8px"
                          />
                          <GraphRange
                            start={110}
                            color="#99c964"
                            width="14%"
                            height="8px"
                          />
                          <GraphRange
                            start={120}
                            color="#efde66"
                            width="15%"
                            height="8px"
                          />
                          <GraphRange
                            start={140}
                            color="#edb868"
                            width="15%"
                            height="8px"
                          />
                          <GraphRange
                            start={160}
                            color="#eb5b83"
                            width="14%"
                            height="8px"
                          />
                          <GraphRange
                            start={180}
                            color="#b73d5f"
                            width="14%"
                            height="8px"
                          />
                          <h4>SYS</h4>
                        </Box>

                        <Box
                          style={{
                            textAlign: "left",
                            marginTop: "10%",
                            marginLeft:
                              Math.round(
                                ((result.vitals.bp_dia - 40) / 85) * 100
                              ) + "%",
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        <Box
                          style={{
                            width: "100%",
                            height: "15px",
                            marginBottom: "10%",
                          }}
                        >
                          <GraphRange
                            start={40}
                            color="#b73d5f"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={50}
                            color="#eb5b83"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={60}
                            color="#edb868"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={70}
                            color="#99c964"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={80}
                            color="#efde66"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={90}
                            color="#edb868"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={100}
                            color="#eb5b83"
                            width="12.5%"
                            height="8px"
                          />
                          <GraphRange
                            start={110}
                            color="#b73d5f"
                            width="12.5%"
                            height="8px"
                          />
                          <h4>DIA</h4>
                        </Box>

                        <p>Blood Pressure</p>
                      </Box>
                    
                  </Box>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#2F99F1",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Key Insights
                  </h6>
                  {result.vitals.bp_sys > 130 || result.vitals.bp_dia > 80 ? (
                    <>
                      <p className={classes.content}>{insights[0].content}</p>
                      <video
                        src={insights[0].video}
                        id="video"
                        controls="true"
                        style={{
                          width: "80%",
                          height: "100%",
                          padding: "5% 10%",
                          borderRadius: "15%",
                        }}
                      ></video>
                      <p
                        className={classes.content}
                        style={{ textAlign: "right" }}
                      >
                        Source: {insights[0].source}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className={classes.content}>{insights[1].content}</p>
                      <video
                        src={insights[1].video}
                        id="video"
                        controls="true"
                        style={{
                          width: "80%",
                          height: "100%",
                          padding: "5% 10%",
                          borderRadius: "15%",
                        }}
                      ></video>
                      <p
                        className={classes.content}
                        style={{ textAlign: "right" }}
                      >
                        Source: {insights[1].source}
                      </p>
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#192853",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                    }}
                  >
                    Heart Health
                  </h6>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "0 35px",
                    }}
                  >
                    
                      <h6
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 14,
                          color: "#8b8b8b",
                          marginTop: 0,
                          marginBottom: 15,
                          fontWeight: "400",
                        }}
                      >
                        Heart Rate variability
                      </h6>

                      <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.heart_scores.sdnn == "--"
                            ? "--"
                            : result.metadata.heart_scores.sdnn.toFixed(2)}
                        </h3>
                        <h4>msec</h4>

                        {result.metadata.heart_scores.sdnn != "--" ?
                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              (result.metadata.heart_scores.sdnn.toFixed(2) <=
                              97
                                ? result.metadata.heart_scores.sdnn.toFixed(2) -
                                  2
                                : "95") + "%",
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        :
                        <></>
                        }
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start="Low"
                            color="#efde66"
                            width="30%"
                            height="8px"
                          />
                          <GraphRange
                            start="Normal"
                            color="#99c964"
                            width="50%"
                            height="8px"
                          />
                          <GraphRange
                            start="High"
                            color="#059b78"
                            width="20%"
                            height="8px"
                          />
                        </Box>

                        <p>SDNN</p>
                      </Box>
                      <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.heart_scores.rmssd == "--"
                            ? "--"
                            : result.metadata.heart_scores.rmssd.toFixed(2)}
                        </h3>
                        <h4>msec</h4>

                        {result.metadata.heart_scores.rmssd != "--" ?  
                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              (result.metadata.heart_scores.rmssd.toFixed(2) <=
                              90
                                ? result.metadata.heart_scores.rmssd.toFixed(2)
                                : "95") + "%",
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        :
                        <></>
                        }
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start="Low"
                            color="#efde66"
                            width="20%"
                            height="8px"
                          />
                          <GraphRange
                            start="Normal"
                            color="#99c964"
                            width="60%"
                            height="8px"
                          />
                          <GraphRange
                            start="High"
                            color="#059b78"
                            width="20%"
                            height="8px"
                          />
                        </Box>

                        <p>RMSSD</p>
                      </Box>
                      <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.heart_scores.pNN50_per == "--"
                            ? "--"
                            : result.metadata.heart_scores.pNN50_per.toFixed(2)}
                        </h3>
                        <h4>%</h4>

                        {result.metadata.heart_scores.pNN50_per != "--" ?  
                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              (result.metadata.heart_scores.pNN50_per.toFixed(
                                2
                              ) <= 90
                                ? (result.metadata.heart_scores.pNN50_per)
                                : "95") + "%",
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        :
                        <></>
                        }
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start="Low"
                            color="#efde66"
                            width="10%"
                            height="8px"
                          />
                          <GraphRange
                            start="Normal"
                            color="#99c964"
                            width="70%"
                            height="8px"
                          />
                          <GraphRange
                            start="High"
                            color="#059b78"
                            width="20%"
                            height="8px"
                          />
                        </Box>

                        <p>PNN50</p>
                      </Box>

                      <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.cardiovascular.cardiac_out == "--"
                            ? "--"
                            : result.metadata.cardiovascular.cardiac_out.toFixed(2)}
                        </h3>
                        <h4>L/min</h4>

                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              ( Math.round((result.metadata.cardiovascular.cardiac_out/10) * 100) ) + "%"
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start="Low"
                            color="#efde66"
                            width="40%"
                            height="8px"
                          />
                          <GraphRange
                            start="Normal"
                            color="#99c964"
                            width="40%"
                            height="8px"
                          />
                          <GraphRange
                            start="High"
                            color="#eb5b83"
                            width="20%"
                            height="8px"
                          />
                        </Box>

                        <p>Cardiac Output</p>
                      </Box> 

                      <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.cardiovascular.map == "--"
                            ? "--"
                            : result.metadata.cardiovascular.map.toFixed(2)}
                        </h3>
                        <h4>mm/Hg</h4>

                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              ( Math.round((result.metadata.cardiovascular.map )) ) + "%"
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start="Low"
                            color="#efde66"
                            width="40%"
                            height="8px"
                          />
                          <GraphRange
                            start="Normal"
                            color="#99c964"
                            width="40%"
                            height="8px"
                          />
                          <GraphRange
                            start="High"
                            color="#eb5b83"
                            width="20%"
                            height="8px"
                          />
                        </Box>

                        <p>Mean Arterial Pressure</p>
                      </Box>    

                    
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.heart_scores.HRMax}</h3>
                      <h4>bpm</h4>
                      <p
                        style={{
                          color: "#787878",
                          fontSize: "10px",
                          textAlign: "justify",
                          padding: "0 20px",
                          margin: "5px 0 0 0",
                        }}
                      >
                        <FcInfo
                          style={{ marginRight: "3%", fontSize: "13px" }}
                        />
                        It is possible to exceed the upper limit of your zone
                        without any ill effects, as long as you do not have
                        coronary artery disease or are at risk for a heart
                        attack.
                      </p>
                      <p>Heart Rate Max</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.heart_scores.HRR}</h3>
                      <h4>bpm</h4>
                      <p>Heart Rate Reserve</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.heart_scores.THRR}</h3>
                      <h4>bpm</h4>
                      <p>Target HR Range</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.heart_scores.heart_utilized}</h3>
                      <h4>%</h4>

                      <Box
                        style={{
                          textAlign: "left",
                          marginLeft:
                            Math.round(
                              ((result.metadata.heart_scores.heart_utilized -
                                25) /
                                76) *
                                100
                            ) + "%",
                        }}
                      >
                        <BsFillCaretDownFill />
                      </Box>
                      <Box style={{ width: "100%", height: "15px" }}>
                        <GraphRange
                          start={25}
                          color="#efde66"
                          width="34%"
                          height="8px"
                        />
                        <GraphRange
                          start={50}
                          color="#99c964"
                          width="33%"
                          height="8px"
                        />
                        <GraphRange
                          start={75}
                          color="#eb5b83"
                          width="33%"
                          height="8px"
                        />
                      </Box>

                      <p>Heart Utilised</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.vo2max}</h3>
                      <h4>mL/kg/min</h4>
                      <p>VO2Max</p>
                    </Box>
                  </Box>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#2F99F1",
                      marginTop: 25,
                      marginBottom: 15,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Key Insights
                  </h6>
                  <>
                    <p className={classes.content}>{insights[2].content}</p>
                    <video
                      src={insights[2].video}
                      id="video"
                      controls="true"
                      style={{
                        width: "80%",
                        height: "100%",
                        padding: "5% 10%",
                        borderRadius: "15%",
                      }}
                    ></video>
                    <p
                      className={classes.content}
                      style={{ textAlign: "right" }}
                    >
                      Source: {insights[2].source}
                    </p>
                  </>
                </TabPanel>

                <TabPanel>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#192853",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                    }}
                  >
                    Physiological Scores
                  </h6>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "0 35px",
                    }}
                  >
                    <Box className={classes.ScanBox}>
                      <h3>
                        {result.metadata.physiological_scores.bloodvolume}
                      </h3>
                      <h4>ml</h4>
                      <p>Blood Volume</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.bmi}</h3>
                      <h4>kg/m²</h4>
                      {result.metadata.physiological_scores.bmi != "--" ?  
                        <Box
                          style={{
                            textAlign: "left",
                            marginLeft:
                              (result.metadata.physiological_scores.bmi <= 40
                                ?  (((result.metadata.physiological_scores.bmi - 15) / 25) * 100)
                                : "95") + "%",
                          }}
                        >
                          <BsFillCaretDownFill />
                        </Box>
                        :
                        <></>
                        }
                        <Box style={{ width: "100%", height: "15px" }}>
                          <GraphRange
                            start="0"
                            color="#efde66"
                            width="15%"
                            height="8px"
                          />
                          <GraphRange
                            start="18.5"
                            color="#99c964"
                            width="25%"
                            height="8px"
                          />
                          <GraphRange
                            start="25"
                            color="#efde66"
                            width="20%"
                            height="8px"
                          />
                          <GraphRange
                            start="30"
                            color="#edb868"
                            width="20%"
                            height="8px"
                          />
                          <GraphRange
                            start=">35"
                            color="#eb5b83"
                            width="20%"
                            height="8px"
                          />
                        </Box>
                      <p>BMI</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.tbw}</h3>
                      <h4>Ltrs</h4>
                      <p>Total Body Water</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.tbwp}</h3>
                      <h4>%</h4>
                      <p>Body Water Percentage</p>
                    </Box>
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.bodyfat}</h3>
                      <h4>%</h4>
                      <p>Total Body Fat</p>
                    </Box>
                    {(result.metadata.physiological_scores.cal_carb == '--')?
                    <></>
                    :
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.cal_carb}</h3>
                      <h4>%</h4>
                      <p>Calorie from Carb</p>
                    </Box>
                    }
                    {(result.metadata.physiological_scores.cal_fat == '--')?
                    <></>
                    :
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.cal_fat}</h3>
                      <h4>%</h4>
                      <p>Calorie from Fat</p>
                    </Box>
                    }
                    <Box className={classes.ScanBox}>
                      <h3>{result.metadata.physiological_scores.weight}</h3>
                      <h4>Kgs</h4>
                      <p>Body Weight</p>
                    </Box>
                  </Box>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#2F99F1",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Key Insights
                  </h6>
                  <>
                    <p className={classes.content}>{insights[3].content}</p>
                    <video
                      src={insights[3].video}
                      id="video"
                      controls="true"
                      style={{
                        width: "80%",
                        height: "100%",
                        padding: "5% 10%",
                        borderRadius: "15%",
                      }}
                    ></video>
                    <p
                      className={classes.content}
                      style={{ textAlign: "right" }}
                    >
                      Source: {insights[3].source}
                    </p>
                  </>
                </TabPanel>
                <TabPanel>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#192853",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                    }}
                  >
                    Stress
                  </h6>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "0 35px",
                    }}
                  >

                    <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.heart_scores.stress_index == "--"
                            ? "--"
                            : result.metadata.heart_scores.stress_index.toFixed(
                                2
                              )}
                        </h3>

                        {result.metadata.heart_scores.stress_index == "--" ? 
                        <></>
                        :     
                        <>
                        {(result.metadata.heart_scores.stress_index.toFixed(2) < 0.50 ) ?
                        <h3 style={{color: '#32b509', margin:'2% 0'}}>LOW</h3>
                        :
                        (result.metadata.heart_scores.stress_index.toFixed(2) >= 0.50 && result.metadata.heart_scores.stress_index.toFixed(2) < 1.5 ) ?
                          <h3 style={{color: '#61b80d', margin:'2% 0'}}>NORMAL</h3>
                          :
                          (result.metadata.heart_scores.stress_index.toFixed(2) >= 1.5 && result.metadata.heart_scores.stress_index.toFixed(2) < 5 ) ?
                            <h3 style={{color: '#eea508', margin:'2% 0'}}>ELEVATED</h3>
                            :
                            (result.metadata.heart_scores.stress_index.toFixed(2) >= 5 && result.metadata.heart_scores.stress_index.toFixed(2) < 9) ?
                              <h3 style={{color: '#f31e2e', margin:'2% 0'}}>HIGH</h3>
                              :
                              <h3 style={{color: '#b4020f', margin:'2% 0'}}>VERY HIGH</h3>
                        }
                        </>
                        }
                        <p>Stress Index</p>
                    </Box>

                    <Box className={classes.ScanBox}>
                        <h3>
                          {result.metadata.heart_scores.rmssd == "--" ? (
                            <>
                              <p className={classes.content}>
                                Not enought data to process. This happens when
                                the signal strength of the scan is poor due to
                                improper light or too much movements.
                              </p>
                            </>
                          ) : (
                            <>
                              {result.metadata.heart_scores.rmssd < 65 ? (
                                <>
                                  {result.vitals.heart_rate > 100 ||
                                  result.vitals.bp_sys > 130 ||
                                  result.vitals.bp_dia > 90 ? (
                                    <>
                                      <h3>More Sympathetic Interactions</h3>
                                      <p
                                        style={{
                                        color: "#787878",
                                        fontSize: "10px",
                                        textAlign: "justify",
                                        padding: "0 20px",
                                        margin: "5px 0 0 0",
                                        fontWeight: '400'
                                        }}
                                    >
                                        Although HRV manifests as a function of
                                        your heart rate, it actually originates
                                        from your nervous system. Your autonomic
                                        nervous system, which controls the
                                        involuntary aspects of your physiology,
                                        has two branches, parasympathetic
                                        (deactivating) and sympathetic
                                        (activating).
                                      </p>
                                      <p
                                            style={{
                                            color: "#787878",
                                            fontSize: "10px",
                                            textAlign: "justify",
                                            padding: "0 20px",
                                            margin: "5px 0 0 0",
                                            fontWeight: '400'
                                            }}
                                        >
                                        {" "}
                                        The <b>
                                          sympathetic nervous system
                                        </b>{" "}
                                        (often called “fight or flight”)
                                        reflects responses to things like stress
                                        and exercise, and increases your heart
                                        rate and blood pressure.{" "}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <h3>
                                        More Parasympathetic Interactions
                                      </h3>
                                      <p
                                            style={{
                                            color: "#787878",
                                            fontSize: "10px",
                                            textAlign: "justify",
                                            padding: "0 20px",
                                            margin: "5px 0 0 0",
                                            fontWeight: '400'
                                            }}
                                        >
                                        Although HRV manifests as a function of
                                        your heart rate, it actually originates
                                        from your nervous system. Your autonomic
                                        nervous system, which controls the
                                        involuntary aspects of your physiology,
                                        has two branches, parasympathetic
                                        (deactivating) and sympathetic
                                        (activating).
                                      </p>
                                      <p
                                            style={{
                                            color: "#787878",
                                            fontSize: "10px",
                                            textAlign: "justify",
                                            padding: "0 20px",
                                            margin: "5px 0 0 0",
                                            fontWeight: '400'
                                            }}
                                        >
                                        The{" "}
                                        <b>parasympathetic nervous system</b>{" "}
                                        (often referred to as “rest and digest”)
                                        handles inputs from internal organs,
                                        like digestion or your fingernails and
                                        hair growing.{" "}
                                      </p>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <h3>Balanced <br/>ANS Interactions</h3>
                                  <p
                                        style={{
                                        color: "#787878",
                                        fontSize: "10px",
                                        textAlign: "justify",
                                        padding: "0 20px",
                                        margin: "5px 0 0 0",
                                        fontWeight: '400'
                                        }}
                                    >
                                    When you have high heart rate variability,
                                    it means that your body is responsive to
                                    both sets of inputs (parasympathetic and
                                    sympathetic). This is a sign that your
                                    nervous system is balanced, and that your
                                    body is very capable of adapting to its
                                    environment and performing at its best.
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </h3>
                        <h4></h4>
                        <p>Autonomic Nervous Systems (ANS)</p>
                      </Box>
                    
                  </Box>
                    
                    

                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#2F99F1",
                      marginTop: 25,
                      marginBottom: 15,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Key Insights
                  </h6>
                  <>
                    <p className={classes.content}>{insights[4].content}</p>
                    <video
                      src={insights[4].video}
                      id="video"
                      controls="true"
                      style={{
                        width: "80%",
                        height: "100%",
                        padding: "5% 10%",
                        borderRadius: "15%",
                      }}
                    ></video>
                    <p
                      className={classes.content}
                      style={{ textAlign: "right" }}
                    >
                      Source: {insights[4].source}
                    </p>
                  </>
                </TabPanel>
                <TabPanel>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#192853",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                    }}
                  >
                    Health Risk Score
                  </h6>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "10% 5%",
                      textAlign: 'center'
                    }}
                  >
                    <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={4}
                      arcsLength={[0.2, 0.2, 0.2, 0.4]} 
                      colors={["#32b509", "#a0dd0c", "#eea508", "#f31e2e"]} 
                      arcWidth={0.2}
                      percent={result.health_risk_score * 0.01}
                      textColor="#000"
                      needleColor="#aeaeae"
                    />

                        {result.health_risk_score == "--" ? 
                        <></>
                        :     
                        <Box style={{width:'100%', textAlign: 'center'}}>
                        {(result.health_risk_score <= 20 ) ?
                        <h3 style={{color: '#32b509', margin:'2% 0'}}>LOW</h3>
                        :
                        (result.health_risk_score > 20 && result.health_risk_score <= 40 ) ?
                          <h3 style={{color: '#a0dd0c', margin:'2% 0'}}>MEDIUM</h3>
                          :
                          (result.health_risk_score > 40 && result.health_risk_score <= 60 ) ?
                            <h3 style={{color: '#eea508', margin:'2% 0'}}>HIGH</h3>
                            :
                            <h3 style={{color: '#f31e2e', margin:'2% 0'}}>VERY HIGH</h3>
                        }
                        </Box>
                        }
                  </Box>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#2F99F1",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Key Insights
                  </h6>
                  <>
                    <p className={classes.content}>
                      The health risk assessment (HRA) is used to provide
                      individuals with an evaluation of their health risks and
                      quality of life. The HRA score is determined from an
                      internal matrix based on your vital parameters. In case
                      you see a trend of high risk scores, we recommend you to
                      consult your physician or can also connect with one of our
                      expert health advisor.
                    </p>
                  </>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      color: "#2F99F1",
                      marginTop: 0,
                      marginBottom: 15,
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0 10%",
                    }}
                  >
                    Recommended Doctors
                  </h6>
                  <Carousel>
                    <div>
                      
                        <Box onClick={demoDoctorProfile}>
                          <img
                            src={doc1}
                            alt="logo"
                            style={{
                              width: "80%",
                              margin: "0 10%",
                              border: "1px solid #c1c1c1",
                              borderRadius: "20px",
                            }}
                          />
                        </Box>
                      
                    </div>
                    <div>
                      <Link href="https://carenowdoc.com/devi-singh">
                        <Box>
                          <img
                            src={doc2}
                            alt="logo"
                            style={{
                              width: "80%",
                              margin: "0 10%",
                              border: "1px solid #c1c1c1",
                              borderRadius: "20px",
                            }}
                          />
                        </Box>
                      </Link>
                    </div>
                    <div>
                      <Link href="https://carenowdoc.com/ajay-tiwari">
                        <Box>
                          <img
                            src={doc3}
                            alt="logo"
                            style={{
                              width: "80%",
                              margin: "0 10%",
                              border: "1px solid #c1c1c1",
                              borderRadius: "20px",
                            }}
                          />
                        </Box>
                      </Link>
                    </div>
                    <div>
                      <Link href="https://carenowdoc.com/aratrika-das">
                        <Box>
                          <img
                            src={doc4}
                            alt="logo"
                            style={{
                              width: "80%",
                              margin: "0 10%",
                              border: "1px solid #c1c1c1",
                              borderRadius: "20px",
                            }}
                          />
                        </Box>
                      </Link>
                    </div>
                  </Carousel>
                  <Button
                    onClick={viewMoreProfile}
                    style={{
                      backgroundColor: "#192853",
                      color: "#fff",
                      borderRadius: "10px",
                      marginBottom: '25px'
                    }}
                  >
                    Find More Doctor
                  </Button>
                </TabPanel>
              </Tabs>
            ) : (
              <Box
                style={{ width: "100%", padding: "5% 0", textAlign: "center" }}
              >
                Loading...
              </Box>
            )}
          </Box>

          <Box className={classes.ScanInfo}>
            <h4>Disclaimer:</h4>
            <p>
              In case of an emergency, please contact your physicial. This data
              is only for monitoring purpose and should not be considered as a
              medical device.{" "}
            </p>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button className={classes.CancelBtn} onClick={newScanFunc}>
                Dashboard
              </Button>
              
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default ScanResult;
const useStyles = makeStyles(() => ({
  Scan: {
    display: "flex",
    flexDirection: "column",
    paddingTop:30,
    left: 0,
    right: 0,
    "& a": {
      textDecoration: "none",
    },
  },
  content: {
    textAlign: "justify",
    fontSize: "12px",
    fontWeight: "200",
    padding: "0 10%",
    color: "#464646",
  },
  ScanBox: {
    border: "1px solid #D3D3D3",
    borderRadius: 20,
    padding: "0 10px",
    textAlign: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "2% 0",
    "& h3": {
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: "600",
      marginBottom: 0,
      color: "#2F99F1",
      marginTop: 10,
    },
    "& h4": {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: "400",
      color: "#192853",
      margin: 0,
    },
    "& p": {
      fontFamily: "Poppins",
      fontSize: 14,
      color: "#2F99F1",
    },
  },
  ScanInfo: {
    width: "100%",
    background: "#1b2a55",
    height: "auto",
    padding: "5% 0",
    bottom: "0",
    "& p": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.7)",
      fontWeight: "400",
      fontSize: 12,
      padding: "0 20px",
      marginTop: 0,
      marginBottom: 10,
      lineHeight: 1.5,
    },
    "& h4": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.8)",
      fontWeight: "400",
      fontSize: 16,
      marginTop: 10,
      marginBottom: 10,
    },
    "& h3": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "#fff",
      fontWeight: "400",
      marginTop: 40,
      marginBottom: 0,
      "& span": {
        fontSize: 24,
        fontWeight: "600",
        color: "#2F99F1",
        marginRight: 5,
      },
    },
  },
  ScanCamera: {
    width: "88%",
    margin: "0 6%",
    height: "auto",
    position: "relative",
    "& .control-dots": {
      display: "none",
    },
    "& .carousel .slider": {
      height: "auto",
    },
    "& .carousel.carousel-slider .control-arrow": {
      bottom: "initial",
    },
    '& .carousel .thumbs-wrapper':{
        margin: '0'
    },
    "& .carousel .control-next.control-arrow:before": {
      borderLeft: "8px solid #333",
    },
    "& .carousel .control-prev.control-arrow:before": {
      borderRight: "8px solid #333",
    },
    "& .carousel-status": {
      display: "none",
    },
    "& .carousel .control-next.control-arrow": {
      right: 5,
    },
    "& .carousel.carousel-slider .control-arrow:hover": {
      background: "#fff",
    },
    "& .carousel .control-prev.control-arrow": {
      left: 5,
    },
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
  },
  CancelBtn: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#2F99F1",
    textTransform: "capitalize",
    borderRadius: 50,
    padding: "5px 20px",
  },
}));
