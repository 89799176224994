import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import cameraGif from "../Images/camera.gif";
import heartbeatgif from "../Images/heartbeat.gif";
import successGif from "../Images/success.gif";
import failureGif from "../Images/failure.gif";
import analysis from "../Images/analysis.gif";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import fingerScan from "../Algo/videoFingerScan";
import { API_KEY, apiAxios3 } from "../Util/ApiAxios";

const LoadingScreen = ({ screen = "" }) => {
  switch (screen) {
    case "analyzing":
      return (
        <>
          <img src={analysis} alt="analyzing icon" style={{ width: "50%" }} />
          <p style={{ color: "#22538f", fontFamily: "Poppins", fontSize: 18 }}>
            Analyzing Data
          </p>
          <p
            style={{
              marginTop: 0,
              color: "#7f8ea0",
              fontFamily: "Poppins",
              fontSize: 16,
            }}
          >
            Hold tight, the measurement
            <br />
            results are on the way
          </p>
        </>
      );
    case "success":
      return (
        <>
          <img src={successGif} alt="success icon" style={{ width: "50%" }} />
          <p style={{ color: "#22538f", fontFamily: "Poppins", fontSize: 16 }}>
            Scan Successful...
          </p>
        </>
      );
    case "failure":
      return (
        <>
          <img src={failureGif} alt="failure icon" style={{ width: "50%" }} />
          <p style={{ color: "#22538f", fontFamily: "Poppins", fontSize: 16 }}>
            Oops..
            <br />
            We couldn't get enough data to process...
            <br />
            Please try again.
          </p>
        </>
      );
    default:
      return <></>;
  }
};

const scanMessages = [
  "We burn calories while we are asleep because brain activity requires energy",
  "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
  "Always sleep on your back straight since it allows your neck and spine in a neutral position",
  "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
  "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
  "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
  "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
  "Lack of sleep can lead to heart disease, heart attack, or stroke",
  "Exercise boosts brain performance",
  "Heart pumps about 2,000 gallons of blood every day",
  "Your heart will beat about 115,000 times each day",
].sort(() => Math.random() - 0.5);

const getMessage = (type = "", timeElapsed = 0) => {
  if (type !== "scan") {
    if (timeElapsed <= 5000) return "Cover your back camera with your finger";
    else if (timeElapsed <= 10000)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 17000)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 18000) return "We are good to start in 3";
    else if (timeElapsed <= 19000) return "We are good to start in 2";
    else if (timeElapsed <= 20000) return "We are good to start in 1";
  } else {
    if (timeElapsed <= 30000) return "Scan in Progress...";
    else if (timeElapsed <= 40000) return scanMessages[0];
    else if (timeElapsed <= 50000) return scanMessages[1];
    else if (timeElapsed <= 60000) return scanMessages[2];
    else if (timeElapsed <= 70000) return scanMessages[3];
    else if (timeElapsed <= 80000) return scanMessages[4];
    else if (timeElapsed <= 90000) return scanMessages[5];
    else if (timeElapsed <= 100000) return scanMessages[6];
    else if (timeElapsed <= 110000) return scanMessages[7];
    else if (timeElapsed <= 120000) return scanMessages[8];
    else if (timeElapsed <= 130000) return scanMessages[9];
    else if (timeElapsed <= 140000) return scanMessages[10];
  }
};

function FingerScan(props) {
  const classes = useStyles();

  const [loadingScreen, setLoadingScreen] = React.useState("");

  const [scanFrameData, setScanFrameData] = React.useState({
    type: "",
    timeElapsed: 0,
    isFingerInView: 0,
    fps: 0,
  });

  useEffect(() => {
    if (!localStorage.getItem("scan_token")) {
      props.history.push("/select-option");
    } else {
      if (localStorage.getItem("scan_result")) {
        localStorage.removeItem("scan_result");
      }

      fingerScan.onFrame(setScanFrameData);
      fingerScan.onScanFinish(({ raw_intensity, ppg_time, average_fps }) => {
        if (
          average_fps > 0 &&
          raw_intensity.length > 0 &&
          ppg_time.length > 0
        ) {
          setLoadingScreen("analyzing");
          let device = "";
          if (navigator.platform.match(/iPhone|iPod|iPad/)) {
            device = "RPPG_CAREPLIX_FINGER_IOS";
          } else {
            device = "RPPG_CAREPLIX_FINGER_ANDROID";
          }
          const data = {
            scan_token: localStorage.getItem("scan_token"),
            api_key: localStorage.getItem("api_key"),
            employee_id: localStorage.getItem("emp_id")
              ? localStorage.getItem("emp_id")
              : "",
            heart_rate: 0,
            oxy_sat_prcnt: 1,
            posture: localStorage.getItem("posture"),
            resp_rate: 16,
            long: null,
            lat: null,
            ref_id: "",
            updateables: ["heart_rate", "oxy_sat_prcnt", "resp_rate"],
            metadata: {
              physiological_scores: {
                height: localStorage.getItem("height"),
                weight: localStorage.getItem("weight"),
              },
              ppg_time,
              raw_intensity,
              fps: average_fps,
              device,
            },
            platform: "ios",
            app_version: "5.0.0",
          };

          apiAxios3
            .post("/vitals/add-scan", data, {
              headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
              if (response.data.statusCode == 200) {
                localStorage.setItem(
                  "scan_result",
                  JSON.stringify(response.data)
                );
                localStorage.removeItem("scan_token");
                localStorage.removeItem("posture");
                setLoadingScreen("success");
                setTimeout(function () {
                  props.history.push("/result");
                }, 2500);
              } else {
                toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setLoadingScreen("failure");
                setTimeout(function () {
                  props.history.push("/select-option");
                }, 5000);
              }
            })
            .catch((error) => {
              toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoadingScreen("failure");
              setTimeout(function () {
                props.history.push("/select-option");
              }, 5000);
            });
        } else {
          setLoadingScreen("analyzing");
          toast.error(
            "Measurement failed due to inappropriate data. Please try again.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setLoadingScreen("failure");
          setTimeout(function () {
            props.history.push("/select-option");
          }, 5000);
        }
      });
      fingerScan
        .startScan()
        .then(() =>
          console.log("Scan Started").catch((err) => console.error(err))
        );

      // Enable wake lock.
      let wakeLock = null;
      navigator.wakeLock
        ?.request("screen")
        .then((wakeLockSentinel) => {
          wakeLock = wakeLockSentinel;
          console.log("Wake Lock is active!");
        })
        .catch((err) => {
          console.log("Wakelock Error");
          console.error(err);
        });
      // The Wake Lock request has failed - usually system related, such as battery.

      return () => {
        fingerScan.stopScan(false, true);
        wakeLock
          ?.release()
          .then(() => console.log("Wake Lock Released!"))
          .catch((err) => {
            console.log("Wakelock Error");
            console.error(err);
          });
      };
    }
  }, []);

  const cancelBtnFunc = () => {
    fingerScan.stopScan(true, true);
    window.location.href = "/dashboard";
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div id="container" style={{ display: "none" }}></div>

      {loadingScreen.length > 0 ? (
        <Box className={classes.ScanContainer} style={{ background: "#fff" }}>
          <Box
            style={{
              paddingTop: "60%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingScreen screen={loadingScreen} />
          </Box>
        </Box>
      ) : (
        <Box className={classes.ScanContainer}>
          <Box className={classes.ScanCamera}>
            <video
              width={0}
              height={0}
              autoPlay
              muted
              hidden
              playsInline
              id="video"
            />
            <canvas
              id="canvasOutput"
              style={{ width: "100%", height: "100%" }}
            />
            {scanFrameData.isFingerInView > 0.5 && (
              <img
                src={heartbeatgif}
                alt="heartbeat"
                style={{
                  position: "fixed",
                  top: "30%",
                  left: 0,
                  right: 0,
                  width: "50%",
                  margin: "0 auto",
                }}
              />
            )}
            {fingerScan.canStop() && (
              <Button
                className={classes.SignalStrength}
                style={{
                  right: "auto",
                  left: "10px",
                  backgroundColor: "#ff0002",
                  textTransform: "capitalize",
                }}
                onClick={() => fingerScan.stopScan()}
              >
                Finish Scan
              </Button>
            )}
            <Box
              className={classes.SignalStrength}
              style={{
                backgroundColor:
                  scanFrameData.isFingerInView > 0.5
                    ? scanFrameData.isFingerInView > 0.9
                      ? "#a0dd0c"
                      : "#eea508"
                    : "#f31e2e",
              }}
            >
              Signal Strength:&nbsp;
              <span style={{ color: "black", fontWeight: 600 }}>
                {scanFrameData.isFingerInView > 0.5
                  ? scanFrameData.isFingerInView > 0.9
                    ? "Good"
                    : "Moderate"
                  : "Poor"}
              </span>
            </Box>
            <Box className={classes.ScanInfo}>
              <h3>
                {scanFrameData.type === "scan"
                  ? `${
                      Math.floor(scanFrameData.timeElapsed / 1000) - 20
                    }% Completed`
                  : `Calibration in progress...`}
              </h3>
              <h4>
                {getMessage(scanFrameData.type, scanFrameData.timeElapsed)}
              </h4>

              <Button onClick={cancelBtnFunc} className={classes.CancelBtn}>
                Cancel Scan
              </Button>
            </Box>
          </Box>
          {fingerScan.isInitializing() && (
            <Box className={classes.CameraLoading}>
              <img src={cameraGif} alt="camera" style={{ width: "60%" }} />

              <h3>Accessing Camera</h3>
              <h4>Camera not opening?</h4>
              <p>
                In case your camera is not opening, you need to allow camera
                permission from your browser settings and phone settings.
              </p>

              <Button onClick={cancelBtnFunc} className={classes.CancelBtn}>
                Cancel Scan
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default FingerScan;
const useStyles = makeStyles(() => ({
  ScanContainer: {
    position: "relative",
    height: "100vh",
  },
  CameraLoading: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h3": {
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: "600",
      color: "rgb(51 73 139)",
      margin: "0 0 15px",
    },
    "& h4": {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: "600",
      color: "#2e3d66",
      margin: "0 0 15px",
    },
    "& p": {
      lineHeight: "22px",
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: "400",
      color: "#616b88",
      margin: "0 0 30px",
    },
  },
  ScanCamera: {
    position: "relative",
    height: "100%",
    width: "100%",
    background: "linear-gradient(180deg, #34476f14, white)",
  },
  SignalStrength: {
    position: "fixed",
    top: "10px",
    right: "10px",
    borderRadius: "25px",
    padding: "5px 10px",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: "400",
    color: "white",
  },
  ScanInfo: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255,255,255,0.8)",
    padding: "25px 15px",
    "& h3": {
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: "600",
      color: "#2F99F1",
      margin: "0 0 10px",
    },
    "& h4": {
      whiteSpace: "pre-line",
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: "600",
      color: "rgb(51 73 139)",
      margin: "0 0 15px",
    },
  },
  CancelBtn: {
    padding: "10px 20px",
    borderRadius: "50px",
    margin: "0px auto",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#1e3063",
    border: "1px #1e3063 solid",
  },
}));
